import React from "react"
import { Link } from "gatsby"
import { css } from "styled-components"
import PropTypes from "prop-types"

import { Logo } from "./logo"

const headerStyles = css`
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
  width: 100vw;
  z-index: 1;
  position: relative;

  & > div {
    padding: 5px;

    svg {
      height: 90px;
    }
  }
`

const navStyles = css`
  padding-bottom: 20px;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    transition: height 300ms;
    height: ${({ count }) => `calc(${count} * 40px)`};

    @media (min-width: 480px) {
      height: unset;
      flex-direction: row;
    }
  }

  li {
    font-size: 1em;
    height: 40px;
    letter-spacing: 0.2em;
    list-style: none;
    margin: 0 10px;
    padding: 10px;

    @media (min-width: 480px) {
      font-size: 0.7em;
      margin-top: 0;
    }

    a {
      color: rgba(89, 89, 89, 0.7);
      text-transform: uppercase;
      text-decoration: none;
    }
  }
`

const Header = ({ siteTitle }) => {
  const links = [
    { name: "Home", path: "/" },
    { name: "Gallery", path: "/gallery" },
    { name: "Schedule", path: "/schedule" },
    { name: "Travel", path: "/travel" },
    { name: "Registry", path: "/registry" },
    { name: "RSVP", path: "/rsvp" },
  ]

  return (
    <header css={headerStyles}>
      <div>
        <Logo />
      </div>
      <nav css={navStyles} count={links.length}>
        <ul>
          {links.map(({ name, path }) => (
            <li key={name}>
              <Link to={path}>{name}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
