import React from "react"
import PropTypes from "prop-types"
import { css } from "styled-components"

import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      css={css`
        height: 100vh;
        display: flex;
        flex-direction: column;

        header,
        footer {
          flex: none;
        }

        main {
          flex: auto;
        }
      `}
    >
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans|The+Girl+Next+Door&display=swap"
          rel="stylesheet"
        />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <footer></footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
