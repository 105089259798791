import React from "react"

export const Logo = () => (
  <svg x="0px" y="0px" viewBox="0 0 4504.36 1728">
    <g>
      <path
        d="M4298.79,907.32c-2.51-2.16-5.17-1.37-7.43,0.77c-0.62,0.6-1.18,1.27-1.7,1.95c-7.04,9.15-13.87,18.5-20.49,27.86
		c-12.95,18.35-26.72,36.14-41.1,53.21c-18.37,21.82-34.15,45.61-53.39,66.74c-12.66,13.91-24.62,28.5-37.37,42.39
		c-0.89,0.98-1.47,2.8-3.61,2.26c1.83-12.02,3.74-23.96,5.46-35.91c3.22-22.38,8.78-44.4,10.96-66.95
		c2.1-21.84,6.66-43.37,7.39-65.37c0.44-12.62,1.7-25.18,2.47-37.78c0.69-11.04,2.41-22.05,1.1-33.13
		c-1.12-9.45-4.53-11.75-13.62-9.24c-10.11,2.82-19.14,7.81-27.59,13.93c-2.28,1.66-4.11,3.72-4.5,6.68
		c-2.18,16.11-11.65,28.05-22.27,39.42c-6.95,7.45-13.72,15.11-20.22,22.96c-4.63,5.58-10.09,9.82-16.52,13.04
		c-5.83,2.91-9.69,1.58-11.33-4.82c-1.02-3.99-1.72-8.05-2.06-12.23c-0.75-9.4,1.62-18.6,1.41-27.94
		c-0.25-10.28,1.56-20.28,3.67-30.29c3.22-15.07,5.9-30.18,4.77-45.73c-1.02-14.28-5.63-26.88-17.65-35.39
		c-13.58-9.61-26.28-10.82-38.43,2.22c-5.67,6.1-10.82,12.5-15.3,19.56c-8.95,14.12-16.36,29.21-26.8,42.41
		c-5.04,6.35-9.65,13.06-14.39,19.66c-11.96,16.63-23.54,33.55-36.56,49.39c-11.29,13.72-23.83,26.36-35.56,39.71
		c-8.32,9.47-18.85,14.45-30.54,17.25c-12.46,2.97-21.55-1.37-26.09-12.04c-3.45-8.08-6.06-16.5-8.05-25.1
		c-2.93-12.48-0.85-25.14-1.85-37.7c-0.17-2.08,0.81-3.78,2.45-5.4c12.75-12.64,26.68-24,38.92-37.26
		c14.41-15.61,21.05-33.8,20.09-54.24c-0.81-17.19-8.37-25.39-27.07-24.35c-6.35,0.33-12.81,1.06-18.83,3.38
		c-30.1,11.56-51.79,31.84-65.2,61.45c-9.38,20.74-20.66,40.34-35.6,57.96c-17.77,20.95-36.7,40.83-54.64,61.63
		c-6.39,7.41-14.18,13.76-23.23,17.96c-12.41,5.75-19.89,2.68-25.35-9.74c-3.47-7.87-4.73-16.09-5.46-24.6
		c-2.39-27.61-4.07-55.18-1.29-82.87c1.27-12.54,4.36-24.95,3.8-37.62c-0.62-13.66,2.76-26.74,4.77-39.98
		c2.57-16.9,5.96-33.67,9.2-50.44c1.31-6.77,2.24-13.83,6.37-19.58c8.68-12.04,17.02-24.45,26.74-35.62
		c14.49-16.63,25.08-35.48,34.48-55.14c2.89-6,3.96-12.54,3.34-19.22c-1.25-13.41-12.27-21.84-24.41-18.58
		c-5.71,1.54-10.88,4.19-15.78,7.6c-19.1,13.33-32.99,31.41-45.65,50.4c-4.17,6.25-8.89,12.33-11.83,19.41
		c-12.43,30.02-23.58,60.45-29.04,92.65c-2.39,14.07-5.88,27.71-14.61,39.36c-31.64,42.2-61.39,85.9-99.15,123.23
		c-21.34,21.09-43.97,40.5-68.36,57.88c-7.76,5.54-16.07,10.28-25.31,15.17c1.35-4.75,2.43-8.53,3.51-12.29
		c1.37-3.8,1.52-7.87,2.84-11.67c2.59-11.94,3.24-23.85,1.45-36.06c-1.74-11.98-2.59-24.33-8.1-35.12
		c-7.81-15.32-14.95-31.14-27.15-43.89c-6.19-6.48-11.85-13.49-17-20.86c-1.12-1.39-1.14-2.78,0.02-4.17
		c6.21-7.85,13.43-14.95,18.16-23.89c5.48-10.36,2.86-24.08-5.75-31.28c-6.29-5.25-16.61-4.07-26.18,3.28
		c-5.07,3.88-9.76,8.26-14.86,12.1c-14.3,10.82-16.23,26.24-16.44,42.49c-0.1,6.91,5.69,13.41,2.95,19.83
		c-2.7,6.35-8.26,11.42-12.08,17.35c-5.96,9.36-13.12,17.87-20.22,26.2c-10.5,12.31-20.93,24.7-31.26,37.16
		c-6.93,8.37-13.37,17.13-20.03,25.7c-7.41,9.53-14.68,19.16-21.55,29.08c-3.34,4.84-6.19,10.17-10.57,14.05
		c-13.49,11.94-24.68,26.14-38.28,37.91c-4.84,4.19-9.47,8.72-14.72,12.29c-11.06,7.47-23.42,2.45-25.95-10.55
		c-1.95-9.99-3.53-20.05-2.86-30.35c1.14-18.14,3.53-36.12,6.33-54.06c2.8-17.75,5.73-35.35,2.49-53.54
		c-3.01-17-12.48-28.98-27.32-36.06c-23.87-11.38-33.57-8.39-50.57,7.31c-14.47,13.33-25.7,29.31-37.78,44.61
		c-7.22,9.15-12.95,19.12-17.75,29.71c-0.66,1.52-0.81,3.67-3.43,4.42c2.45-18,4.59-35.64,7.31-53.23
		c4.07-26.34,7.76-52.67,6.41-79.45c-0.89-17.65-7.62-31.74-23.29-40.73c-7.81-4.48-11.56-2.84-14.14,5.85
		c-4.86,16.4-13.12,31.18-22.96,44.94c-20.05,28.05-42.66,54.14-63.15,81.9c-13.45,18.25-29.37,34.44-44.28,51.48
		c-9.22,10.52-21.07,16.65-34.85,18.83c-10.13,1.6-19.53-6.73-18.89-16.88c0.58-9.47,1.41-18.93,4.13-28.13
		c1.45-4.96,2.57-10.05,3.45-15.13c5.27-30.47,7.64-61.32,13.14-91.8c5.15-28.42-8.93-45.26-34.48-54.93
		c-5.56-2.1-7.16-0.21-8.43,5.81c-2.32,10.94-4.13,22.38-10.09,31.91c-9.99,15.94-20.14,31.95-31.99,46.46
		c-10.92,13.35-21.61,26.92-33.15,39.82c-20.18,22.5-39.48,45.79-59.21,68.71c-20.53,23.87-42.02,46.85-65.06,68.3
		c-9.49,8.82-19.85,16.75-32.34,21.09c-14.86,5.17-24.37-0.77-27.8-16.05c-4.92-21.86-7.31-43.95-7.39-66.3
		c-0.04-12.46-1.04-24.97-0.02-37.33c1.68-20.01,3.07-39.98,3.32-60.06c0.12-9.53,1.47-18.93,3.97-28.17
		c1.87-6.1,1.37-12.6,3.09-18.75c1.04-2.84-0.62-7.39,5.11-7.14c42.72,1.95,85.32-3.72,128.04-1.95c3.38,0.15,6.44-1.22,6.44-5.17
		c-0.02-3.88-2.93-4.53-6.23-4.44c-19.68,0.46-39.38-0.83-59.06,1.37c-22.07,2.45-44.24,0.83-66.37,1.18c-6.77,0.1-6.77,0-4.82-6.6
		c2.84-7.18,2.74-14.91,4.19-22.34c0.02-0.5,0.02-1,0.04-1.49c0.52-0.71,0.89-1.47,0.96-2.35c0.95-13.22,4.05-26.07,6.27-39.09
		c0.02-0.04,0.02-0.1,0.02-0.15v-0.04c2.45-5.34,2.78-11.21,4.11-16.82l-0.19-0.39l0.27-0.33c3.3-11.58,5.31-23.46,7.16-35.35v-0.02
		c0-0.02,0.02-0.06,0.02-0.08c2.55-6.77,2.3-14.1,4.15-20.99l-0.21-0.33l0.27-0.31c2.03-4.3,1.93-9.09,3.01-13.6v-0.02h0.02
		c0-0.02,0-0.02,0-0.04c3.05-4.71,2.49-10.4,4.15-15.49c0,0,0,0.02-0.02,0.02c1.93-6.52,2.86-13.58,9.2-17.81c0.02,0,0.02,0,0.04,0
		c0,0,0-0.02,0.02-0.02c1.45-0.27,2.41-1.29,3.24-2.35c6.66-8.7,14.05-16.69,21.63-24.58c0,0,0-0.02,0.02-0.02s0.04-0.02,0.06-0.04
		c1.97-1.2,3.11-3.09,4.15-5.07c0,0,0.02-0.02,0.02-0.04c0,0,0.02,0,0.02-0.02c1.95-1.62,3.36-3.65,4.34-5.98l0.02-0.04
		c1.64-0.95,2.12-2.72,2.97-4.21h0.02v-0.02c2.1-1.6,2.89-4.05,3.94-6.31c0,0,0.02,0,0.02-0.02s0-0.04,0.02-0.06
		c2.53-2.57,2.78-6.12,4.23-9.18c0.02-0.02,0.02-0.06,0.02-0.08v-0.02c0-0.02,0.02-0.02,0.02-0.04c2.41-6.46,2.78-12.89-0.02-19.33
		c-0.02-0.02-0.02-0.02-0.02-0.04c0-0.02-0.02-0.04-0.02-0.06c-1-3.53-3.24-6.35-6.66-7.04c-9.55-1.93-18.64-1-26.14,6.12
		c-0.02,0-0.04,0.02-0.06,0.02c-1.91,0.85-3.34,2.16-4.07,4.17l-0.02,0.02v0.02c-2.82,2.37-5.54,4.84-7.39,8.1v0.02h-0.02
		c-0.02,0.02-0.02,0.02-0.02,0.04l-0.02,0.02c-7.27,7.85-13.24,16.63-18.58,25.89c0,0.02-0.02,0.02-0.04,0.04v0.02
		c-2.14,1.93-3.01,4.63-4.3,7.06v0.02c-1.99,1.72-2.8,4.13-3.84,6.44v0.02c-0.02,0-0.04,0.02-0.04,0.02
		c-1.99,1.54-2.37,3.94-3.24,6.06v0.02l-0.04,0.02v0.02c-2.16,2.37-2.91,5.46-4.17,8.26c0-0.02,0-0.02,0-0.04
		c-1.79,1.64-1.99,4.13-3.18,6.1c-0.33,0.73-0.64,1.43-0.98,2.16c-2.62,5.25-5.34,10.48-7.18,16.09l0.1,0.31l-0.27,0.21
		c-2.53,2.62-2.72,6.23-4.11,9.36v0.02l-0.02,0.02c-2.22,3.36-2.95,7.62-6.23,10.38c-0.31,0.33-0.62,0.64-0.95,0.98
		c-1.39,0.75-2.45,1.81-3.2,3.22c-0.31,0.31-0.6,0.62-0.91,0.93c-3.88,4.42-8.61,8.12-11.6,13.35l-0.08,0.08
		c-2.51,2.3-5.29,4.36-6.83,7.56v0.02c-0.02,0-0.02,0-0.04,0.02c-2.12,1.08-3.09,3.13-4.26,5c0,0,0,0.02-0.02,0.02l-0.02,0.02v0.02
		c-4.67,4.55-7.81,10.19-11.36,15.53c0,0,0,0.02-0.02,0.02c0,0.02,0,0.02-0.02,0.04c-3.07,2.97-5.4,6.44-7.35,10.17
		c-0.02,0.02-0.02,0.02-0.02,0.04c-1.95,1.33-2.91,3.36-3.97,5.34c0,0,0,0.02-0.02,0.02l-0.02,0.02v0.02
		c-5.29,5.48-10.05,11.4-14.53,17.54c0,0.02,0,0.02-0.04,0.04v0.02l-0.02,0.02c-5.67,5.81-10.65,12.12-15.51,18.6l-0.02,0.02v0.02
		l-0.02,0.02c-0.02,0.02-0.02,0.02-0.04,0.04c-7.99,8.62-15.61,17.56-22.77,26.86h-0.02v0.02c-2.93,2.3-5.15,5.23-7.12,8.35h-0.02
		c0,0.02-0.02,0.02-0.02,0.04c0,0.02,0,0.02-0.02,0.04l-0.02,0.02c-5.73,5.73-10.86,11.94-15.57,18.54c-0.02,0-0.02,0.02-0.04,0.02
		c-2.22,0.85-2.33,4.48-5.4,4.19l-0.02-0.02c-0.1-0.1-0.19-0.21-0.29-0.27c-0.91-0.71-2.12-0.58-3.09-0.6
		c-21.94-0.42-43.86-1.1-65.79,0.02c-1.12,0.04-2.47-0.15-3.43,0.85l-0.25,0.12l-0.19-0.21c-5.07,0.75-10.15,0.75-15.24,0.04
		l0.06,0.06c-3.82-1.87-7.91-1.52-11.92-1.29c-2.41,0.12-4.38,1.54-4.92,3.84c-0.52,2.28-0.48,4.71,1.89,6.02
		c0.1,0.06,0.21,0.12,0.31,0.15c0.12,0.06,0.23,0.1,0.35,0.15v0.02c0,0,0.02,0.02,0.04,0.02c2.1,1.91,4.82,1.66,7.29,1.74
		c11.92,0.46,23.83-0.75,35.77-0.48c1.22,0.02,2.39-0.31,3.43-1c10.38-0.64,20.76-0.58,31.14,0c3.76,1.72,7.74,0.91,11.65,1
		c6,0.15,6.04,0.06,3.05,5.34c0,0.02-0.02,0.02-0.02,0.04c-0.02,0.02-0.02,0.02-0.02,0.04c-0.04,0.02-0.04,0.06-0.08,0.08
		c-7.78,9.78-15.44,19.53-22.61,29.79c-0.02,0.04-0.06,0.1-0.1,0.15c0,0.02-0.02,0.02-0.04,0.04l-0.02,0.02
		c-5.36,6.06-10.94,11.94-15.67,18.52c-1.62,1.14-3.22,2.33-4.03,4.26v0.02h-0.02c-1.45,0.62-2.53,1.62-3.05,3.13v0.02h-0.02
		c-1.93,0.83-3.3,2.2-4.13,4.13v0.02h-0.02c-1.93,0.83-3.3,2.2-4.13,4.13v0.02h-0.02c-1.52,0.54-2.55,1.58-3.09,3.09v0.02h-0.02
		c-1.93,0.81-3.3,2.2-4.13,4.13v0.02h-0.02c-1.95,0.83-3.3,2.2-4.13,4.15v0.02h-0.02c-3.05,1.76-5.46,4.17-7.22,7.22l-0.02,0.04
		c0,0.02-0.02,0.02-0.02,0.02c-5.33,4.98-10.15,10.52-15.42,15.59l-0.02,0.02c-0.02,0-0.02,0-0.04,0.02h-0.02v0.02
		c-4.9,3.28-9.3,7.12-13.45,11.31l-0.02,0.02c0,0-0.02-0.02-0.02,0h-0.02v0.02c0,0.02-0.02,0.02-0.02,0.02
		c-3.32,1.72-5.54,4.75-8.39,7.06h-0.02l-0.02,0.02c-6.17,2.47-10.71,7.06-15.42,11.46l-0.02,0.02h-0.02
		c-0.02,0.02-0.02,0.04-0.04,0.04c-3.96,2.51-7.72,5.29-11.38,8.24h-0.02v0.02h-0.02l-0.02,0.02c-2.01,1.1-4.4,1.54-6.1,3.22
		l-0.02,0.02l-0.02,0.02c-3.05,1.54-6.58,1.93-9.42,3.96c-0.02,0-0.02,0.02-0.02,0.02c-5.09,1.66-10.42,2.35-15.47,4.13
		c-0.02,0-0.02,0-0.04,0h-0.02c-7.49,0.71-14.97,0.73-22.44-0.02l-0.23,0.19l-0.27-0.15c-2.91-1.79-6.56-1.25-9.45-3.03l-0.04-0.04
		c0-0.02-0.02-0.02-0.02-0.02h-0.02c-3.18-3.13-7.33-4.67-11.21-6.6c-2.24-1.12-4.59-1.35-6.29,1.2l-0.02,0.04
		c-0.52,0.37-0.91,0.79-1.18,1.25c-0.02,0.02-0.04,0.04-0.04,0.06c-0.69,1.14-0.64,2.47,0.04,3.72c1.25,2.24,3.22,3.94,5.44,5.29
		c0.77,1.87,2.66,2.24,4.17,3.07c0,0,0.02,0,0.02,0.02l0.02,0.02c1.76,2.47,4.63,3.09,7.22,4.13l0.02,0.02c0.02,0,0.02,0,0.04,0
		c3.97,2.41,8.53,2.95,12.91,4.11c0.5,0.04,1,0.08,1.49,0.15c7.22,2.68,14.45,2.84,21.65-0.02c0.04-0.02,0.08-0.02,0.12-0.04
		c11.4-0.98,21.61-5.11,30.95-11.56h0.02c0-0.02,0.02-0.02,0.02-0.02l0.02-0.02l0.02-0.02c2.26-1.02,4.53-2.1,6.23-3.99l0.02-0.02
		l0.02-0.02c3.53-1.89,6.52-4.42,9.15-7.41c0.02,0,0.02-0.02,0.02-0.02c0-0.02,0.02-0.02,0.02-0.04c6.44-4.88,13.06-9.59,18.81-15.3
		c0,0,0.02,0,0.02-0.02h0.02v-0.02c4.55-3.7,9.13-7.37,13.39-11.44c0.02,0,0.02,0,0.04,0v-0.02c0.02,0,0.02-0.02,0.02-0.02
		c1.85-1.22,4.11-1.91,5.19-4.11h0.02v-0.02c4.42-3.18,8.34-6.79,11.23-11.46c0,0,0-0.02,0.02-0.02h0.02v-0.02
		c2.7-2.49,5.38-5.02,7.33-8.2h0.02v-0.02c1.56-0.69,2.8-1.66,3.09-3.47c0.23-0.23,0.48-0.46,0.71-0.71
		c1.89-0.39,3.03-1.56,3.47-3.43c0.25-0.23,0.5-0.48,0.75-0.71c0.69-0.15,1.33-0.39,1.85-0.87c11.79-11.38,23.42-22.92,34.11-35.33
		c2.2-2.55,4.84-4.94,5.9-8.32v-0.02c0,0,0.02,0,0.02-0.02h0.02v-0.02c4.28-4.84,7.89-10.13,11.33-15.57c0-0.02,0.02-0.02,0.02-0.02
		c0-0.02,0.02-0.02,0.02-0.04c3.53-3.22,5.65-7.47,8.35-11.29l0.02-0.02v-0.02c2.51-2.68,5.5-4.92,7.08-8.39
		c0.02-0.02,0.02-0.04,0.02-0.06h0.02v-0.02c2.47-2.99,5.13-4.32,9.55-4.15c12.02,0.5,24.02,1.35,36.04,1.85
		c11.54,0.48,23.06,1.06,34.61,0.93c2.84-0.02,3.99,0.66,3.67,3.86c-0.37,3.92-1.31,7.78-1.58,11.71l0.21,0.31l-0.25,0.27
		c-2.53,6.81-2.68,14.1-4.07,21.13c0,0.06-0.02,0.1-0.04,0.17v0.02c-0.02,0.5-0.06,1-0.08,1.52c-1.64,3.9-1.6,8.08-1.52,12.16
		c0.17,10.01-0.21,20.12-1.6,29.87c-1.47,10.36-1.47,20.57-1.35,30.87c0.02,2.93-0.37,6.23,1.45,8.97v0.02h0.02v0.02
		c0.98,5.21,1.08,10.59,2.97,15.65c0.39,0.83,0.56,1.68,0.08,2.53c0.25,0.66,0.33,1.33,0.35,2.01v0.06
		c0.02,0.64,0.02,1.31,0.12,1.95c1.12,6.79,0.89,13.83,3.67,20.3c0,0.02,0,0.02,0,0.04c0,0.02,0.02,0.02,0.02,0.04
		c1.58,4.75,1.33,10.05,4.19,14.43v0.02c0.02,0.02,0.02,0.02,0.04,0.04c0.95,2.76,1.22,5.77,3.03,8.24v0.02
		c0.02,0.02,0.02,0.02,0.02,0.04h0.02v0.02c1.58,3.34,1.99,7.16,4.21,10.23h0.02v0.02c0,0.02,0,0.02,0,0.04h0.02v0.02
		c2.3,6.23,4.23,12.58,7.22,18.56v0.02c0.02,0.02,0.02,0.04,0.02,0.06c0.02,0.02,0.02,0.02,0.02,0.04c1.12,3.22,2.06,6.46,4.03,9.28
		v0.02l0.02,0.02c0.02,0.02,0.02,0.02,0.02,0.04c1.54,4.07,3.9,7.54,7.12,10.44c0.02,0,0.02,0.02,0.02,0.02
		c6.33,6.75,13.68,12,22.3,15.47l0.29-0.15l0.21,0.23c4.5,1.74,9.01,1.66,13.49-0.04h1.47c3.8-0.5,7.56-1.12,11.15-2.22
		c3.59-1.08,7.02-2.64,10.19-4.98c4.65-1.99,8.72-4.8,12.25-8.45h0.02l0.02-0.04c0.02,0,0.02-0.02,0.04-0.02s0.02-0.02,0.02-0.02
		c9.3-6.75,17.33-15.01,25.87-22.67c0.02-0.02,0.02-0.02,0.04-0.04l0.02-0.02h0.04v-0.02c4.42-3.16,8.18-6.95,11.36-11.36l0.06-0.06
		c6.14-6.23,12.23-12.5,18.37-18.72c2.26-2.26,5.11-4.11,6.39-7.27h-0.02c-0.02,0.02-0.02,0.02-0.04,0.02
		c6.04-6.48,13.2-11.92,17.92-19.6c0,0.02,0,0.02,0,0.04c2.39-2.78,5.29-5.13,7.18-8.37h0.02v-0.02c0.02-0.02,0.02-0.02,0.02-0.04
		l0.02-0.02c6.6-6.87,12.87-14.07,18.5-21.8l0.06-0.06v-0.02c5.65-5.81,10.77-12.02,15.57-18.54v-0.02h0.02c0-0.02,0-0.02,0.02-0.04
		l0.02-0.02c2.99-2.24,5.13-5.17,7.12-8.26l0.02-0.02c0.02-0.02,0.04-0.04,0.04-0.06l0.02-0.02c3.32-4.26,8.22-6.89,11.44-11.23
		c0,0,0.02,0,0.02-0.02c0-0.02,0-0.02,0.02-0.04l0.02-0.02c4.34-5,7.99-10.55,12.5-15.4c0.02-0.02,0.02-0.02,0.04-0.04
		c2.7-2.53,5.36-5.09,7.06-8.43c0.02,0,0.02,0,0.04-0.02c0.02-0.02,0.02-0.02,0.02-0.04c1.62-1.52,3.24-3.01,4.03-5.17
		c0.02-0.02,0.02-0.04,0.02-0.06l0.02-0.02v-0.02c6.21-8.3,13.78-15.55,18.7-24.77c0.02,0,0.02-0.02,0.04-0.02v-0.02
		c3.18-2.89,5.25-6.58,7.39-10.23h0.02v-0.02c2.68-1.52,3.09-4.94,5.5-6.71c0.1-0.06,0.21-0.15,0.31-0.21
		c0.15-0.25,0.31-0.48,0.46-0.73c1.43-2.22,3.07-4.3,4.07-6.77c0.06-0.08,0.08-0.19,0.12-0.27c0.02-0.04,0.02-0.08,0.04-0.12
		c0.27-0.77,0.56-1.52,0.79-2.33c0.25-0.23-0.19-0.93,0.42-0.75c-0.06,0.04-0.12,0.12-0.15,0.27c-0.23,1-0.48,2.01-0.83,3.01
		c-0.08,0.29-0.17,0.58-0.29,0.87v0.02c-0.31,0.79-0.71,1.56-1.18,2.28c-2.2,2.97-1.52,6.85-3.11,10.01
		c-0.04,0.08-0.1,0.19-0.15,0.27c-0.08,0.17-0.19,0.31-0.29,0.48c-1.43,5.04-2.39,10.17-3.49,15.3l-0.02,0.02
		c-0.31,0.42-0.64,0.79-0.75,1.31c-2.74,13.12-5.4,26.26-6,39.67c-0.48,10.63,0.46,21.22,3.67,31.45c0,0.04,0.02,0.06,0.02,0.1
		c1,3.45,0.73,7.31,3.07,10.36c0,0.02,0.02,0.02,0.02,0.02c1.37,3.09,1.43,6.79,4.15,9.26v0.02c0.02,0,0.02,0.02,0.04,0.02
		c1.7,4.23,4.17,7.95,7.25,11.31c0.02,0.02,0.02,0.02,0.02,0.04l0.02,0.02c0.02,0,0.02,0.02,0.02,0.02
		c5.88,10.67,15.26,17.42,25.85,22.75h0.02c0.02,0,0.02,0,0.04,0.02c0.02,0,0.02,0.02,0.04,0.02c1.74,1.76,3.92,2.49,6.19,3.07
		c0,0,0,0.02,0.02,0v0.02c0.02,0,0.02,0,0.04,0c9.11,4.96,18.7,5.79,27.98,1.16c18.02-8.97,32.07-22.69,44.38-38.38h0.02v-0.02
		c1.68-1.1,3.36-2.22,3.99-4.3l0.02-0.04c3.8-3.57,7.83-6.95,10.42-11.63c0.23-0.25,0.46-0.5,0.69-0.75
		c1.08-0.25,1.64-1.14,2.37-1.87c5.98-6.06,10.46-13.27,15.57-20.01c-0.04,0.02-0.06,0.04-0.1,0.06c0.02-0.02,0.04-0.04,0.06-0.06
		c3.53-3.59,7.58-6.75,9.42-11.69l-0.08-0.33l0.27-0.19c1.18-1.87,3.16-3.16,3.82-5.38c0,0,0.02,0,0.02-0.02l0.02-0.02h0.02v-0.02
		c2.93-2.68,5.38-5.75,7.37-9.2c0.02,0,0.02-0.02,0.04-0.02c0-0.02,0-0.02,0-0.04c2.55-2.1,4.63-4.59,6.19-7.51
		c0.23-0.27,0.44-0.52,0.66-0.79c3.61-2.76,5.79-6.54,7.66-10.57c-0.06-0.58,0.21-0.85,0.79-0.81c5.38-4.09,8.35-9.92,11.56-15.59
		c0.02-0.02,0.02-0.02,0.04-0.02v-0.02c0.02-0.02,0.02-0.02,0.02-0.04c3.63-4.96,7.72-9.57,11.42-14.49l0.02-0.02
		c2.89-4.65,6.95-8.49,9.24-13.56c0,0,0,0,0-0.02c0.04-0.04,0.06-0.1,0.08-0.15c0.02-0.04,0.02-0.1,0.04-0.15
		c0.33-1.06,0.85-2.08,1.12-3.18c0.02-0.12,0.06-0.23,0.1-0.35c0.06-0.17,0.12-0.33,0.19-0.48c0.25-0.58,0.56-1,0.6-1.06
		c-0.06,0.12-0.29,0.54-0.5,1.06c0,0,0,0-0.02,0c-0.04,0.15-0.12,0.31-0.17,0.48c-0.04,0.06-0.06,0.15-0.08,0.21
		c-0.39,1.14-0.66,2.33-1,3.49c-0.06,0.25-0.15,0.5-0.23,0.73v0.02c-0.27,0.89-0.58,1.76-1.04,2.59c-1.35,3.8-1.58,7.87-2.89,11.67
		c0,0.02,0,0.02-0.02,0.04c0,0.02,0,0.02,0,0.04c-2.18,4.23-2.3,8.93-3.09,13.47v0.04c-2.24,4.92-2.74,10.32-4.11,15.49v0.02
		l-0.02,0.02c-1.33,1.29-1.2,3.09-1.6,4.71c-1.7,7.1-2.05,14.39-2.62,21.65v0.04c0.48,0.85,0.31,1.7-0.1,2.53
		c-1,1.1-1,2.55-1.22,3.88c-2.35,13.83-2.26,27.71-1.08,41.6c0.52,5.96,0.02,12.08,2.3,17.83c0.04,0.58,0.08,1.14,0.12,1.72
		c0.6,4.17,1.72,8.14,4.26,11.6l0.04,0.04c0.95,2.01,1.64,4.23,4.01,5.15l0.02,0.02v0.02h0.02c1.66,2.62,4.61,2.99,7.1,4.23
		c0.02,0,0.02,0,0.04,0.02h0.02l0.02,0.02c2.86,2.24,6.25,3.13,9.69,4.03l0.35-0.19l0.27,0.27c5.48,2.12,11.19,2.72,17.02,2.62
		c2.49-0.04,4.13-1.16,4.63-3.67v-0.02c0.02-0.02,0.02-0.02,0.02-0.04c1.74-1.85,1-4.19,1.14-6.33c0.31-4.46,0.42-8.89,2.64-13
		c5.44-10.11,10.4-20.43,15.84-30.52h0.02v-0.02c0-0.02,0.02-0.04,0.02-0.06c0,0,0-0.02,0.02-0.02c5.9-8.84,13-16.79,18.66-25.8
		c0-0.02,0.02-0.02,0.04-0.04l0.02-0.02v-0.02c5.36-6.06,10.23-12.54,15.63-18.54c0,0,0.02,0,0.02-0.02c4.21-3.38,8.2-7,11.21-11.56
		v-0.02h0.02c0,0,0-0.02,0.02-0.02c2.26-1.74,5-2.64,7.47-3.99c1.04-0.52,2.08-0.54,3.11-0.04c2.18,1.25,4.44,2.37,6.14,4.28
		l0.31,0.33l-0.15,0.44c3.26,13.7,2.1,27.4,0.02,41.1l0.19,0.35l-0.25,0.33c-1.39,1.74-1.33,3.96-1.43,5.94
		c-0.83,15.3-4.3,30.25-5.79,45.44l0.21,0.25l-0.19,0.25c-2.35,3.78-1.14,8.08-1.37,12.04c-0.44,7.76-1.27,15.65,1.39,23.29
		l0.15,0.23l-0.19,0.21c0.6,7.35,3.47,13.83,7.22,20.01c0.02,0.04,0.04,0.1,0.06,0.15c0,0.02,0,0.02,0,0.04h0.02v0.02
		c1.18,4.15,3.2,7.97,5.31,11.65c6.56,11.36,16.01,19.56,28.15,24.64l0.39-0.15l0.27,0.31c10.71,4.63,21.4,4.94,32.07-0.1
		c0.02,0,0.04,0,0.06-0.02c2.78-1.33,6.04-1.72,8.2-4.19h0.02c0.02-0.02,0.02-0.02,0.02-0.04c1.64-0.66,3.11-1.54,4.13-3.05
		c0.02-0.02,0.04-0.02,0.04-0.04c10.52-8.37,20.16-17.71,29.93-26.9c0.02-0.02,0.04-0.04,0.06-0.06h0.02
		c0.02-0.02,0.02-0.02,0.04-0.04c1.85-0.91,3.4-2.1,4.03-4.19c0,0,0,0,0-0.02l0.02-0.02c1.58-0.93,2.37-2.47,3.16-4.03
		c0-0.02,0.02-0.02,0.02-0.04l0.02-0.02v-0.02c5.5-5.65,11.33-11,16.69-16.73c5.36-5.71,11.46-11,14.51-18.58
		c0.87-3.45,3.05-6.08,5.23-8.76c5.67-6.98,11.81-13.64,16.73-21.11c8.39-12.75,17.73-24.81,28.15-35.77
		c13.97-14.72,25.64-31.14,38.16-46.94c4.17-5.25,7.93-10.82,12-16.38c2.08,2.26,2.45,4.92,3.49,7.27
		c8.72,19.22,12.41,40.17,20.34,59.68c2.24,5.48,3.38,11.25,3.84,17.13c0.93,12.06,3.38,23.89,5.52,35.75
		c1.74,9.69,4.17,19.18,3.4,29.21c-0.31,4.15-1.81,6.17-5.46,7.58c-14.07,5.42-26.24,13.83-36.97,24.31
		c-6.73,6.58-11.42,14.32-12.06,23.98c-0.58,8.49,3.53,12.91,12.12,13.12c2.22,0.06,4.53-0.17,6.66-0.75
		c17.58-4.77,31.51-15.69,43.8-28.29c10.61-10.86,18.62-24,26.47-37.03c1.45-2.43,3.32-4.19,5.63-5.87
		c7.87-5.73,17.06-9.09,25.14-14.45c32.97-21.86,62.19-48.22,90.53-75.63c11.73-11.33,20.76-24.93,31.37-37.22
		c12.54-14.51,22.17-31.24,34.65-45.84c6.85-8.03,12.29-17.21,20.01-25.37c0.5,9.51-0.62,18.21-1.12,26.97
		c-1.79,31.14-1.7,62.17,4.26,93c2.99,15.44,7.22,30.31,16.63,43.1c19.2,26.14,44.69,30.91,71.06,14.1
		c8.53-5.46,16.34-12.16,23.08-19.93c7.91-9.13,15.78-18.33,23.94-27.26c13.14-14.37,27.24-27.88,37.86-44.4
		c2.16-3.36,4.17-6.98,8.2-10.38c-0.27,4.15-0.44,7.18-0.64,10.21c-0.48,7.47-0.44,15.07,1.83,22.13
		c7.58,23.62,19.82,44.36,39.19,60.41c5.25,4.34,10.69,8.22,17.52,9.61c11.13,2.24,22.46,3.07,33.53,0.95
		c17.81-3.4,33.07-11.71,43.97-26.78c2.14-2.97,4.92-5.31,7.49-7.87c15.05-14.78,28.15-31.3,41.14-47.83
		c7.22-9.22,12.85-19.6,20.03-28.94c11.98-15.59,24.04-31.18,33.8-48.37c5.27-9.3,10.26-18.83,17.11-27.15
		c1.76-2.16,3.59-4.19,6.19-5.42c2.8-1.35,4.94-0.66,5.42,2.43c0.75,4.94,1.79,9.9,0.58,14.95c-2.06,8.55-4.19,17.08-6.06,25.7
		c-3.8,17.6-5.69,35.48-6.81,53.41c-1.49,24.27,5.52,43.03,29.6,54.37c21.88,10.3,35.68,7.66,49.88-7.62
		c4.82-5.19,9.65-10.38,14.12-15.88c5.96-7.35,12.62-13.99,19.16-21.09c1.35,2.12,0.6,3.99,0.42,5.75
		c-2.47,23.37-4.24,46.81-8.05,70.04c-2.78,17.04-3.72,34.4-6.75,51.38c-6.6,37.12-13.08,74.19-14.84,111.93
		c-0.08,2.16-0.71,4.11-1.99,5.79c-5.54,7.08-9.96,15.03-15.44,21.98c-9.99,12.62-17.04,26.76-24.52,40.75
		c-8.97,16.84-14.66,34.56-14.57,53.93c0.1,16.63,12.12,26.14,27.98,20.9c17.23-5.69,29.27-18.12,38.26-33.3
		c11.67-19.74,18.52-41.5,24.81-63.42c6.71-23.46,10.5-47.54,15.28-71.39c0.54-2.74,1.74-4.59,3.65-6.52
		c13.39-13.51,26.16-27.61,38.55-42.02c18.12-21.09,35.23-42.99,51.96-65.2c9.82-13.06,20.28-25.74,30.87-38.24
		c10.05-11.85,18.58-24.83,28.34-36.89c5.54-6.83,9.59-14.76,15.44-21.42C4300.28,912.27,4301.26,909.46,4298.79,907.32z
		 M3818.48,832.65c4.67-8.8,7.89-18.35,14.1-26.36c3.07-3.97,6.87-6.44,12.02-5.4c4.82,0.98,8.84,5.56,8.99,11.4
		c0.48,17.25-3.9,32.97-15.71,46.17c-9.69,10.84-19.72,21.26-30.91,30.52c-1.04,0.87-1.79,2.14-4.07,2.51
		C3806.05,871.1,3808.75,851.05,3818.48,832.65z M3640.12,679.53c1.62-6.19,3.92-12.08,6.95-17.73c5.4-10.07,8.26-21.15,12.04-31.87
		c2.99-8.43,8.05-15.2,14.18-21.28c4.11-4.07,8.2-3.09,10.73,2.16c3.45,7.12,2.18,14.37-0.95,21.01
		c-7.49,15.92-16.23,31.18-27.4,44.86c-7.29,8.91-14.16,18.12-21.9,27.03C3635.88,695.64,3638.02,687.59,3640.12,679.53z
		 M2510.57,603.06c4.4-12.75,10.19-24.83,17.75-36.04c1.54-2.28,3.3-4.42,5.09-6.52c2.59-3.05,6.04-3.59,9.34-1.85
		c3.22,1.7,3.18,5.04,2.51,8.26c-0.6,2.91-2.03,5.48-3.49,8.01c-9.3,16.07-21.17,30.1-35.44,44.03
		C2507.14,612.67,2508.89,607.87,2510.57,603.06z M2419.64,769.88c-2.08,4.67-2.55,9.65-2.91,14.68
		c-2.01,11.92-4.21,23.81-5.98,35.77c-0.52,3.43-2.47,3.24-4.73,3.18c-15.71-0.35-31.45-0.71-47.19-1.12
		c-6.23-0.17-12.43-0.5-18.64-0.85c-1.31-0.06-3.16,0.56-3.78-0.93c-0.56-1.37,0.91-2.45,1.76-3.51
		c14.43-18.12,29.52-35.69,44.63-53.23c14.88-17.27,29.77-34.54,42.78-53.33c5.88-8.51,12.6-16.38,20.66-23.27
		C2438.91,715.34,2428.36,742.31,2419.64,769.88z M3280.63,1078.03c-3.01,0.62-6.83,2.14-8.93-0.12c-1.89-2.06,0.04-5.56,1.08-8.22
		c4.84-12.43,14.7-20.16,25.62-26.72c4.53-2.74,9.26-5.27,14.82-6.6C3313.78,1050.61,3295.56,1074.87,3280.63,1078.03z
		 M3310.88,830.62c-4.86-8.93-5.81-17.96-4.73-26.8c0.79-6.56,6.08-10.96,12.08-13.78c2.28-1.08,4.57-0.12,5.92,1.89
		c3.7,5.42,5.27,11.48,2.26,17.56C3322.57,817.21,3317.15,823.89,3310.88,830.62z M4075.25,1197.19
		c-1.56,19.33-5.5,38.28-9.67,57.13c-1.56,7.08-5.17,13.87-8.72,20.32c-2.76,5-8.26,7.41-13.54,8.93
		c-6.58,1.91-10.34-2.16-10.61-9.4c-0.75-19.47,6.08-36.68,14.28-53.87c8.18-17.08,19.33-32.14,30.29-48.35
		C4078.43,1180.97,4075.92,1189.01,4075.25,1197.19z M1295.47,907.47c-3.82-3.07-6.44-2.78-10.17,1.52
		c-3.43,3.97-6.6,8.1-9.09,12.73h-0.02l-0.02,0.02v0.02c-1.95,1.62-2.89,4.01-4.26,6.1l-0.02,0.02c0,0.02,0,0.02,0,0.04
		c-3.74,4.38-7.91,8.35-11.92,12.46c-4.03,4.13-7.93,8.39-11.02,13.35c-3.84,3.78-7.31,7.87-11.25,11.52c-0.02,0-0.02,0-0.04,0.02
		l-0.02,0.02c-4.26,2.91-8.43,5.96-11.56,10.17l-0.04,0.02c-5.17,3.36-9.92,7.29-14.47,11.44c-2.86,1.97-6.37,2.47-9.34,4.19
		c-0.68-0.02-1.39-0.02-2.08-0.02c-6.1-1.91-8.41-6.31-8.97-12.46c-0.81-8.99-0.19-17.92,0.15-26.86
		c0.42-11.36,1.49-22.65,2.53-33.96c-0.54-0.83-0.39-1.68,0-2.51c1.02-1.29,1.06-2.82,1.1-4.38c0.27-9.88,1.45-19.66,3.03-29.42
		c-0.46-0.85-0.29-1.7,0.1-2.53c0.19-0.29,0.5-0.56,0.54-0.87c1.68-10.38,0.83-20.84,0.89-31.28c0-1.7-0.79-3.32-2.62-4.05
		l-0.02-0.02h-0.02c-3.8-4.5-9.22-6.21-14.43-8.28c-0.02-0.02-0.04-0.02-0.06-0.02h-0.02c-0.02,0-0.04-0.02-0.06-0.02
		c-5.88-2.89-12.02-4.75-18.54-5.19c-3.72-0.25-6.31,1.02-8.99,4.46c-5.65,7.27-6.56,15.76-8.49,24.06l0.17,0.25l-0.19,0.21
		c-1.81,2.62-2.45,5.75-3.97,8.49h-0.02v0.02c-2.14,1.45-2.28,3.97-3.22,6.08c0,0.02-0.02,0.02-0.04,0.04v0.04c0,0,0,0-0.02,0.02
		h-0.02c-0.46,0.5-0.83,1.06-1.18,1.62c0,0.02,0,0.04-0.02,0.04c-1.37,2.24-2.1,4.84-3.8,7c-4.69,5.96-9.09,12.14-13.51,18.29
		c-0.02,0.02-0.04,0.04-0.04,0.06l-0.02,0.02c-1.45,1.41-2.59,3.11-3.92,4.61c-5.42,6.04-10.01,13.87-20.3,10.88l0.1,0.06
		c-4.19-1.41-7.93-3.18-7.99-8.55c-0.02-1.52-0.19-3.07-0.23-4.61c-0.27-12.21,1.37-24.27,3.01-36.33c0.02-0.52,0.06-1.04,0.08-1.56
		c1.81-4.36,1.62-9.15,2.95-13.62v-0.04c0,0,0-0.02,0.02-0.02v-0.02c2.37-4.48,2.53-9.61,4.15-14.32c0-0.02,0-0.04,0.02-0.06v-0.02
		c0.02-0.02,0.04-0.02,0.04-0.04c2.33-3.45,2.22-7.76,4.17-11.33c0-0.02,0.02-0.02,0.02-0.04v-0.02c0,0,0-0.02,0.02-0.02
		c3.32-5.52,7.22-10.57,11.33-15.53h0.02v-0.02c0.02,0,0.02-0.02,0.02-0.02c1.43-0.6,2.37-1.72,3.2-2.99
		c2.16-1.25,4.15-2.74,6.23-4.13c1.72-0.04,3.45-0.06,5.19-0.08c6,2.55,11.23,5.63,12.97,12.83c0.73,2.95,3.63,5.44,7.29,4.11
		c3.01-1.12,3.47-4.23,2.99-7c-0.64-3.72-1.74-7.29-4.42-10.28c-5.65-6.31-12.81-9.86-20.97-11.25
		c-17.06-2.95-32.49,2.57-47.58,9.65c-0.02,0.02-0.04,0.02-0.06,0.02c-11.75,5.09-21.59,12.46-27.86,23.94c0-0.02,0-0.04,0-0.04
		c-0.02,0.02-0.02,0.02-0.04,0.04c-2.35,2.26-2.91,5.46-4.26,8.22c0-0.02,0-0.02,0-0.04c-3.16,5.21-5,11.02-7.12,16.67l-0.02,0.02
		c-1.6,1.39-2.33,3.28-3.09,5.15c0,0,0,0.02-0.02,0.02s-0.02,0.02-0.04,0.04c0,0.02,0,0.02,0,0.04c-3.86,4.19-6.46,9.07-8.39,14.39
		v0.02h-0.02v0.02c-2.1,2.01-2.55,4.92-3.97,7.31l-0.02,0.02v0.04l-0.04,0.02v0.02c-3.59,5.65-9.13,9.92-11.5,16.42
		c0,0,0,0.02-0.02,0.02c-0.79,0.69-1.43,1.43-2.12,2.2c-7.91,8.97-14.91,18.75-23.23,27.34c-3.9,4.05-8.59,7.33-12.08,11.85
		c-0.35,0.35-0.71,0.73-1.06,1.1c-1.2,0.89-2.49,1.7-2.93,3.26v0.02h-0.02c-1.56,0.5-2.51,1.62-3.16,3.05
		c0,0.02-0.02,0.02-0.02,0.04c-9.2,7.39-18.02,15.2-27.07,22.73c-0.35,0.33-0.68,0.66-1.04,1c-4.07,3.11-7.89,6.56-11.94,9.72
		c-7.27,5.67-14.97,10.69-22.32,16.23c-2.49,1.2-5.13,2.16-7.12,4.26h-0.02l-0.02,0.02h-0.02c-3.43,1.02-5.9,3.76-9.24,4.96
		c-0.44-0.42-0.75-0.81-0.95-1.18c-0.12-0.25-0.19-0.48-0.23-0.71c-0.17-0.91,0.27-1.74,0.79-2.57c0.1-0.15,0.21-0.31,0.31-0.46
		c0.31-0.46,0.64-0.93,0.87-1.41c0.62-2.33,1.49-4.59,1.6-7c0.02-0.12,0.02-0.27,0.02-0.39v-0.15c0.15-0.39,0.29-0.81,0.42-1.2
		c0.6-2.01,0.91-4.11,1.33-6.17c0-0.02,0-0.06,0.02-0.08c0.21-1.04,0.46-2.12,0.81-3.11c2.03-8.68,2.18-17.54,1.37-26.26
		c-0.79-8.61-0.6-17.46-3.59-25.8c0-0.04-0.02-0.06-0.02-0.1c0-0.02-0.02-0.02-0.02-0.02c-0.87-3.51-1.02-7.24-2.97-10.46
		l-0.25-0.27l0.19-0.31c-1.08-3.38-1.33-7.08-4.15-9.67c0,0.02,0,0.02,0,0.04c-1.29-2.51-1.97-5.34-4.11-7.35l-0.02-0.02
		c-0.98-2.1-1.18-4.59-3.2-6.12v-0.02h-0.02c-3.05-6.66-6.25-13.2-11.42-18.56v-0.02h-0.02v-0.02c0-0.02-0.02-0.02-0.02-0.04
		c-1.87-3.86-5.23-6.44-8.1-9.45v-0.02h-0.02l-0.02-0.02c-4.3-6.19-9.63-11.54-13.99-17.71c-2.06-2.93-1.89-4.65,0.73-6.89
		c2.49-2.1,4.28-4.9,5.98-7.68h0.02v-0.02h0.02v-0.02c2.14-1.1,2.78-3.4,4.11-5.15c0,0,0.02,0,0.02-0.02h0.02v-0.02
		c3.3-3.24,5.83-6.95,7.16-11.4l0.02-0.02c0-0.02,0-0.02,0-0.02v-0.02c0.02,0,0.02-0.02,0.02-0.02c2.95-6.12,2.86-12.25,0.04-18.41
		v-0.02c-0.02-0.02-0.02-0.02-0.02-0.04c-1.12-3.4-2.39-6.75-5.27-9.13c0,0-0.02,0-0.02-0.02c0-0.02-0.02-0.04-0.04-0.06
		c-2.45-3.4-5.83-5.29-9.94-5.23c-5.17,0.08-10.32,0.66-14.76,3.8c-7.31,5.11-14.45,10.42-20.59,16.92l-0.02,0.02h-0.02v0.02
		c-8.41,5.5-11.52,13.8-12.43,23.31l0.17,0.21l-0.17,0.23c-4.3,9.34-2.97,18.68-0.02,28.02l0.19,0.21l-0.15,0.25
		c0.69,3.36,3.22,6.68,0.1,10.07l-0.04,0.04c0,0.02-0.02,0.02-0.02,0.04c-2.78,2.78-5.61,5.5-7.12,9.3c0,0,0,0.02-0.02,0.02
		c0,0.02,0,0.02-0.02,0.04v0.02h-0.02v0.02c-4.03,5.36-7.93,10.73-11.5,16.4c0,0.02,0,0.02-0.02,0.04h-0.02v0.02h-0.02v0.02
		c-3.18,2.57-5.52,5.87-7.85,9.2c-2.35,3.32-4.67,6.66-7.81,9.34c-1.7,1.06-3.11,2.39-4.13,4.15l-0.02,0.02
		c-3.86,4.42-7.93,8.66-11.17,13.62c-0.02,0.02-0.02,0.02-0.04,0.04v0.02c-0.02,0.02-0.02,0.02-0.02,0.04
		c-3.59,3.4-6.75,7.24-10.09,10.86c-4.28,4.61-9.3,8.53-12.73,13.93c-0.02,0.02-0.02,0.04-0.06,0.06v0.02
		c-7.58,8.68-15.55,16.98-22.92,25.78h-0.02v0.02c-3.67,2.47-7.37,4.96-10.26,8.39h-0.02v0.02c0,0.02-0.02,0.02-0.02,0.02
		c-1.62,0.69-3.03,1.68-4.19,3.03h0.04c0,0-0.02,0-0.02,0.02c-0.02,0-0.02,0-0.04,0c-2.57,1.81-5.69,2.37-8.26,4.09
		c-0.02,0-0.02,0-0.04,0v0.02h-0.02c-4.53,0.89-9.03,1.62-13.54-0.12h-0.02c-2.99-2.28-5.42-5.02-6.66-8.68
		c-0.21-0.19-0.39-0.39-0.56-0.6c-0.15-0.17-0.27-0.33-0.37-0.5c-0.77-1.12-1.22-2.43-1.74-3.7c-0.15-0.25-0.17-0.52-0.08-0.81
		c-2.86-7.35-3.59-15.07-3.99-22.86c-0.15-2.51,0.48-5.17-1.1-7.47c-0.39-1.56-0.6-3.11-0.1-4.69c-1.72-23.19-2.55-46.4,0-69.58
		c-0.54-1.2-0.29-2.39,0.1-3.57c2.1-6.98,1.89-14.26,3.03-21.36l-0.21-0.19l0.15-0.23c1.49-1.76,1.2-3.9,1.14-5.92
		c-0.44-12.5,0.23-24.85,3.01-37.08c0-0.04,0.02-0.1,0.04-0.15v-0.02c-0.5-0.83-0.37-1.68,0.02-2.53c2.2-7.93,2.66-16.17,4.11-24.25
		c0.04-0.56,0.08-1.14,0.12-1.7c1.72-3.11,1.1-6.68,2.01-9.94c0.02-0.54,0.06-1.08,0.12-1.6c0.04-0.37,0.08-0.73,0.17-1.08
		c0.15-0.93,0.35-1.83,0.6-2.72v-0.02c0.35-1.41,0.77-2.78,1.18-4.17c0.02-0.08,0.04-0.17,0.06-0.25c0.27-0.95,0.52-1.91,0.73-2.91
		c1.14-4.15,1.39-8.49,2.28-12.68c0-0.02,0-0.02,0-0.04c1.91-3.97,2.1-8.49,4.07-12.41l0.02-0.02v-0.02h0.02
		c11.44-13.62,20.66-28.98,32.28-42.49c6.31-7.35,12.46-14.84,16.38-23.83c0-0.02,0.02-0.06,0.04-0.08l0.02-0.02h0.02v-0.02h0.02
		c0-0.02,0-0.02,0-0.04c2.06-2.01,3.05-4.63,4.07-7.22l0.02-0.02h0.02v-0.02c2.59-2.1,2.93-5.38,4.23-8.18l0.02-0.02
		c0-0.02,0-0.02,0-0.04c2.1-2.41,2.93-5.4,4.13-8.24c0-0.02,0.02-0.02,0.02-0.04V637c0,0,0-0.02,0.02-0.02
		c4.71-8.84,6.71-17.96,3.03-27.69c0-0.02,0-0.06-0.02-0.08l-0.02-0.02c-1.47-5.11-4.55-8.86-9.24-11.31c-0.02,0-0.04,0-0.06-0.02
		c-0.02,0-0.02,0-0.04-0.02c-6.1-3.3-12.21-2.82-18.33-0.04h-0.02c-0.02,0.02-0.02,0.02-0.04,0.02c-0.02,0.02-0.02,0.02-0.04,0.02
		c-2.89,1.14-5.98,1.81-8.3,4.05H703v0.02c-0.02,0.02-0.04,0.02-0.06,0.04c-5.5,2.91-10.01,7.12-14.45,11.36l-0.02,0.02
		c-3.05,2.08-5.67,4.63-8.03,7.45c-0.02,0.02-0.02,0.02-0.04,0.04c0,0.02-0.02,0.02-0.02,0.02c-11.06,9.74-18.48,22.34-26.92,34.13
		v0.02l-0.02,0.02v0.02h-0.02c0,0.02,0,0.02-0.02,0.04c-2.22,4.23-5.31,7.93-7.49,12.19c-2.64,1.74-2.99,4.71-3.99,7.35l-0.02,0.02
		c-1.95,2.41-2.06,5.52-3.13,8.26c0,0,0,0.02-0.02,0.02l-0.02,0.02v0.02c-2.41,3.05-2.41,7-3.99,10.38c0,0-0.02,0-0.02,0.02v0.02
		c0,0,0,0.02-0.02,0.02c-2.24,3.2-2.82,7.04-4.26,10.57l0.15,0.37l-0.29,0.27c-1.91,2.08-2.01,4.86-2.95,7.33l-0.02,0.02v0.02
		c-2.28,3.47-2.55,7.66-4.09,11.4v0.04c-0.02,0-0.02,0-0.04,0.02c0,0.02,0,0.02,0,0.04c-2.95,5.25-2.33,11.36-4.17,16.86l0.19,0.33
		l-0.25,0.27c-1.99,3.94-1.97,8.39-2.99,12.58c0,0.02,0,0.04-0.02,0.06v0.04c-2.59,5.71-2.64,12.04-4.21,17.98l0.17,0.25l-0.17,0.23
		c-2.37,5.69-1.64,12.08-4.11,17.75c0,0.02,0,0.04-0.02,0.06c-4.34,6.33-7.51,13.37-11.92,19.72c-5.85,8.43-12.75,16.11-18.1,24.87
		h-0.02c-0.02,0.02-0.04,0.02-0.04,0.04c-1.66,0.85-2.26,2.59-3.26,3.99c-0.29,0.35-0.6,0.68-0.89,1.04
		c-4.3,4.86-7.93,10.21-11.42,15.65h-0.02v0.02c-2.1,1.64-3.05,4.11-4.59,6.17c-5.87,7.81-12.33,15.11-18.1,22.98l-0.02,0.02h-0.02
		c-1.76,0.73-2.26,2.57-3.34,3.9c-0.31,0.35-0.62,0.73-0.91,1.08c-4.01,4.11-6.58,9.32-10.5,13.49c-0.31,0.33-0.64,0.68-0.95,1.04
		c-1.08,1.02-2.24,1.99-3.03,3.28v0.02c-0.02,0-0.02,0.02-0.04,0.02c-3.8,2.66-6.19,6.44-8.35,10.4c0.17,0.35,0.08,0.62-0.25,0.81
		c-2.62,2.93-5.07,5.98-6.91,9.45c-0.02,0-0.02,0-0.04,0.02v0.02l-0.02,0.02v0.02l-0.02,0.02c-9.72,10.15-17.27,22.07-25.8,33.13
		c0,0.02-0.02,0.02-0.02,0.04c0.02,0,0.02-0.02,0.04-0.02c-8.12,8.18-16.46,16.19-23.11,25.72c-0.35,0.37-0.71,0.75-1.04,1.12
		c-1.27,0.83-2.37,1.79-3.05,3.16h-0.02v0.02c-3.07,2.43-6.27,4.77-8.28,8.28h-0.02v0.02c-2.22,2.59-4.86,4.84-7.18,7.35h-0.02v0.02
		c-4.53,3.03-8.37,6.81-11.42,11.31l-0.02,0.02l-0.02,0.02c-3.74,4.19-8.26,7.68-11.25,12.54v0.02h-0.02c0,0.02-0.02,0.02-0.02,0.04
		c-9.74,9.65-19.24,19.53-29.25,28.94c-0.66,0.64-1.33,1.31-2.01,1.95c-7.58,6.95-15.07,13.95-22.71,20.86l-0.04,0.04
		c-0.02,0-0.02,0-0.04,0.02c-4.59,3.67-9.18,7.33-13.39,11.42h-0.02l-0.02,0.02c0,0.02-0.02,0.02-0.02,0.02
		c-1.97,1.06-4.03,2.01-5.31,3.96c-3.28,1.85-6.27,3.99-8.24,7.29c0,0.02-0.02,0.02-0.02,0.02h-0.02v0.02
		c-4.92,4.63-10.92,7.72-16.38,11.56h-0.02v0.02c-0.02,0-0.02,0-0.04,0v0.02c-0.02,0-0.02,0.02-0.04,0.02
		c-2.95,1.79-6.56,2.03-9.47,3.92c0.02,0,0.02,0,0.04,0c-4.5,2.51-9.8,2.26-14.47,4.19h-0.04c0,0.02-0.02,0.02-0.02,0.02l-0.02,0.02
		h-0.04c-0.02,0-0.02,0-0.04,0c-9.69,2.55-19.39,1.33-29.06-0.12h-0.02c-6.79-4.55-14.1-8.37-19.8-14.37v-0.02H243v-0.02
		c-1.06-1.99-2.24-3.86-4.17-5.15h-0.02l-0.02-0.02v-0.02h-0.02c-2.26-3.88-4.53-7.76-7.04-11.5l-0.02-0.02v-0.02l-0.02-0.02v-0.02
		c-1.99-3.9-2.08-8.43-4.26-12.25v-0.02c-0.02-0.02-0.02-0.04-0.02-0.06c-1.08-4.17-1.02-8.59-2.99-12.56l-0.25-0.31l0.19-0.35
		c-1.79-6.23-1.6-12.87-4.13-18.93v-0.06c0,0-0.02,0-0.02-0.02c-0.42-3.51-0.87-7.02-1.6-10.46c-1.27-6.19-0.21-12.66-2.57-18.66
		l-0.15-0.23l0.21-0.21c-0.77-7.66-0.77-15.32,0-22.98l-0.21-0.19l0.12-0.23c1.41-1.58,1.08-3.53,1.18-5.4
		c0.62-12.46,1.02-24.95,3.01-37.28c0-0.02,0.02-0.02,0-0.04c2.01-5.42,2.28-11.25,4.17-16.71c-0.25-0.31-0.21-0.58,0.12-0.79
		c3.99-10.67,7.37-21.55,11.17-32.28v-0.02l0.02-0.02c0-0.02,0-0.02,0.02-0.04v-0.02c2.72-5.71,5.17-11.54,7.2-17.54v-0.02h0.02
		v-0.02c1.87-2.24,2.53-4.94,3.2-7.66c-0.27-0.77-0.15-1.39,0.71-1.68c4.26-7.08,7.7-14.57,11.67-21.78l0.02-0.02v-0.02
		c0-0.02,0.02-0.02,0.02-0.04c3.99-5.38,7.99-10.75,11.33-16.57l0.02-0.02v-0.02c0.02-0.02,0.02-0.04,0.04-0.06
		c3.99-4.67,7.89-9.38,11.44-14.39c0,0,0.02,0,0.02-0.02c0,0,0-0.02,0.02-0.02c6.14-5.9,11.44-12.66,18.58-17.52
		c0.02-0.02,0.06-0.04,0.08-0.06h0.04l0.02-0.02c2.45-1.33,5.23-1.95,7.2-4.13h0.02c0.02,0,0.04-0.02,0.06-0.02
		c4.5-1.79,8.84-3.8,12.31-7.29l0.02-0.02c0.02,0,0.02,0,0.04,0v-0.02c0.02,0,0.02-0.02,0.02-0.02c3.99-2.01,6.33-5.48,7.85-9.47
		c1.22-3.24,1.18-6.41-2.95-7.83l-0.35,0.15l-0.27-0.27c-1.02-0.85-2.06-0.83-3.03,0.06h-0.02v0.02h-0.02
		c-1.79,0.54-3.59,1.22-4.88,2.49c-3.76,3.67-8.41,3.38-12.97,3.03c-4.42-0.33-7.35-4.11-11.31-5.52v-0.02h-0.04v-0.02
		c-0.02-0.04-0.06-0.08-0.08-0.12c-4.07-6.5-7.56-13.31-10.36-20.49c-0.02-0.02-0.02-0.06-0.02-0.08
		c-3.8-17.5-0.93-34.98,0.08-52.48l-0.21-0.31l0.23-0.27c2.06-8.08,4.67-16.01,7.12-23.98c0.02-0.02,0.04-0.02,0.06-0.04v-0.02
		c2.51-2.93,2.57-6.87,4.26-10.17c0-0.02,0-0.02,0.02-0.04v-0.02h0.02v-0.02c2.22-2.37,2.91-5.46,4.03-8.35l0.02-0.02v-0.02h0.02
		v-0.02c4.92-6.14,9.59-12.46,14.61-18.54h0.02v-0.02c6.04-4.09,11.77-8.66,18.54-11.54h0.04c0-0.02,0.02-0.02,0.02-0.02
		c5.73-1.58,11.48-2.12,17.21,0.04l0.31-0.12l0.21,0.25c3.43,2.01,2.51,4.01,0.31,6.48c-5.34,6-9.86,12.5-11.67,20.53v0.04
		c-0.02,0.02-0.02,0.02-0.02,0.04c-2.49,5.81-2.18,11.63,0.04,17.42c0,0.02,0,0.02,0,0.04c0.02,0,0.02,0.02,0.02,0.02
		c0.02,0,0.02,0.02,0.02,0.02c1.29,2.8,0.93,6.39,4.07,8.28v0.02h0.02c0.68,1.37,1.79,2.33,3.03,3.16c0.71,0.35,1.39,0.71,2.1,1.08
		c3.8,1.87,7.56,1.89,11.31-0.1c0.02,0,0.02,0,0.04,0v-0.02h0.02c9.36-3.51,13.64-10.92,15.51-20.2l-0.17-0.23l0.17-0.25
		c1.76-4.84,1.79-9.69-0.02-14.53l-0.15-0.25l0.19-0.23c-0.56-2.99-0.81-6.06-1.83-8.97c-0.5-0.79-0.93-1.56-1.31-2.41
		c0-0.02-0.02-0.02-0.02-0.04c-0.12-0.27-0.25-0.56-0.35-0.85v-0.02c-0.1-0.17-0.17-0.33-0.23-0.5c-0.06-0.15-0.12-0.31-0.19-0.48
		v-0.02c-0.1-0.31-0.23-0.6-0.33-0.91c-2.99-3.49-0.02-5.27,2.03-7.33h0.02c0-0.02,0.02-0.04,0.04-0.06
		c6.77-4.13,13.35-8.62,20.93-11.21l0.23-0.21l0.29,0.15c2.84-0.69,5.83-0.35,8.68-1.18c1.89-0.56,4.44,0.06,4.75-2.93
		c0.27-2.47,0.21-4.86-2.57-6.06h-0.02v-0.02c-0.02,0-0.02,0-0.04,0c-2.72-1.66-5.46-1.52-8.2-0.06c-0.5,0.04-1,0.06-1.52,0.1
		c-3.8,0.69-7.51,1.66-11.17,2.91c-0.29,0.37-0.6,0.71-0.93,0.98c-0.17,0.17-0.33,0.29-0.5,0.39c-0.91,0.69-1.95,1.1-3.11,1.27
		c-0.06,0.02-0.12,0.04-0.21,0.04c-0.08,0.02-0.19,0.04-0.27,0.04c-1.74,0.75-3.72,1.06-4.94,2.74c0,0,0,0,0,0.02
		c-0.02,0.02-0.02,0.02-0.04,0.02c-3.99,1.64-7.35,4.15-10.28,7.27l-0.02,0.02h-0.02c-1.31,0.5-2.62,0.81-3.76-0.31
		c-2.26-2.24-5.29-2.91-8.14-3.96c-0.29,0.23-0.56,0.21-0.77-0.12c-9.72-3.51-19.39-1.56-29.08,0.12l-0.21,0.17l-0.21-0.17
		c-5.38,1.33-11.09,1.2-16.03,4.15c-0.02,0-0.02,0-0.04,0c-0.02,0.02-0.02,0.02-0.04,0.02c-5.79,1.25-10.67,4.55-15.94,6.98
		c-8.43,3.86-14.91,10.34-21.92,16.15c-8.84,7.33-16.01,15.82-20.22,26.63l-0.02,0.02c-2.39,2.68-3.05,6.08-4.09,9.34
		c0,0.02-0.02,0.06-0.02,0.08c-5.81,12.37-7.81,25.45-6.66,38.99c0.77,9.07,2.62,17.94,6.58,26.24l0.23,0.21l-0.12,0.29
		c2.03,7.14,6.1,12.93,11.5,17.92c0,0.02,0.02,0.04,0.02,0.06c0.56,2.12,2.28,3.18,3.92,4.32v0.02h0.02
		c3.99,4.38,7.24,9.63,13.49,11.36l0.02,0.02c0.02,0,0.02,0,0.04,0c6.31,4.15,13.64,5.36,20.76,7.18h0.02v0.02
		c0.64,0.77,0.79,1.52-0.08,2.22h-0.02v0.02c-0.02,0-0.04,0.02-0.06,0.02c-5,1.99-9.86,4.19-14.22,7.43c-0.02,0-0.02,0-0.04,0v0.02
		h-0.02l-0.02,0.02c-2.91,1.06-5.73,2.33-8.32,4.05h-0.02l-0.02,0.02c-0.02,0-0.02,0.02-0.02,0.02c-5.17,1.72-10.32,3.53-14.55,7.14
		l-0.02,0.02c-6.25,3.09-12.1,6.81-17.31,11.48c-0.04,0.02-0.06,0.06-0.1,0.08c-0.02,0-0.04,0-0.06,0.02
		c-0.02,0.02-0.02,0.04-0.04,0.04c-3.74,1.99-6.37,5.25-9.32,8.14h-0.02v0.02h-0.02c-1.56,0.5-2.51,1.62-3.16,3.03l-0.02,0.02h-0.02
		v0.02c-4.42,3.16-8.03,7.18-11.5,11.29l-1.04,1.04c-2.57,2.51-5.02,5.19-7.31,7.95c-6.98,8.45-14.34,16.61-19.58,26.36l-0.02,0.02
		v0.02c-2.2,1.93-3.18,4.59-4.17,7.24c0-0.02,0-0.02,0-0.04c-2.86,4.3-4.98,9.01-7.12,13.7l0.04-0.12l-0.02,0.02
		c-2.74,2.91-2.93,6.81-4.19,10.32l-0.02,0.02v0.02c-1.83,2.89-1.95,6.31-3.11,9.4v0.02c-3.7,7.7-6.48,15.76-8.35,24.08l0.19,0.35
		l-0.29,0.27c-1.16,1.39-1.18,3.13-1.6,4.77c-2.86,11.25-4.36,22.71-5.63,34.23c0.02,0.48,0.02,0.98,0.02,1.45
		c-1.35,2.93-0.04,6.14-0.79,9.11c-0.08,0.27-0.17,0.54-0.25,0.81c0.54,2.41,0.42,4.8,0,7.18c0.08,0.15,0.17,0.31,0.21,0.48
		c0.02,0.04,0.04,0.06,0.02,0.1c0.62,2.01-0.39,4.3,0.85,6.23l0.15,0.23l-0.19,0.19c0.81,5.02,1.64,10.03,2.2,15.09
		c0.33,3.13-0.06,6.52,1.97,9.3v0.02H154c1.47,6.89,4.53,13.18,7.27,19.6c0,0.02,0,0.02,0,0.04h0.02v0.02l0.02,0.02v0.02
		c1.56,3.38,1.47,7.41,4.09,10.36v0.02c0.02,0,0.02,0.02,0.04,0.02c1.02,2.41,0.91,5.29,3.05,7.22v0.02h0.02v0.02
		c1,2.95,2.37,5.71,4.28,8.18v0.02c0.02,0,0.02,0.02,0.04,0.02c1.85,5.34,4.5,10.21,8.18,14.49v0.02h0.02v0.02
		c0.69,1.64,1.45,3.2,3.11,4.09l0.02,0.02v0.02c0.69,2.03,2.33,3.16,3.99,4.28v0.02h0.02c0.75,1.31,1.72,2.39,3.11,3.07h0.02v0.02
		h0.02c1.04,1.7,2.22,3.32,4.23,4.01c0.02,0,0.02,0,0.04,0v0.02c0.54,0.87,1.22,1.58,2.03,2.2c13.2,10.4,27.61,18.23,44.51,20.72
		v0.02h0.02c3.84,3.05,8.66,2.76,13.06,3.84c0.29,0.08,0.58,0.17,0.87,0.25c0.54,0.04,1.06,0.08,1.58,0.12
		c1.41,1.31,3.24,0.98,4.9,1.22c9.8,1.49,19.6,2.2,29.19-1.35c0.02,0,0.04-0.02,0.06-0.02h0.02c4.19-1.2,8.61-1.74,12.39-4.17
		c0.02-0.02,0.04-0.02,0.06-0.04c3.16-1.27,6.64-1.72,9.26-4.15h0.02c0-0.02,0.02-0.02,0.02-0.04c2.1-0.98,4.61-1.08,6.14-3.11h0.02
		v-0.02h0.02v-0.02c10.03-4.71,18.43-11.52,25.93-19.58c0-0.02,0.02-0.02,0.02-0.02c0.02,0,0.02-0.02,0.04-0.02
		c1.6-0.73,2.99-1.7,4.13-3.05l0.02-0.02c0,0,0.02,0,0.02-0.02c4.03-1.99,7.18-5.02,10.23-8.26l0.06-0.06h0.02
		c0.02,0,0.02-0.02,0.02-0.02v-0.02c4.9-2.95,8.28-7.51,12.33-11.4c0.02,0,0.02,0,0.04-0.02h0.02v-0.02c0.02,0,0.02-0.02,0.02-0.02
		c3.28-1.76,5.71-4.59,8.37-7.08h0.02c0-0.02,0.02-0.02,0.02-0.04c8.26-6.91,15.57-14.82,22.96-22.63l0.04-0.04
		c4.07-3.51,7.91-7.27,11.36-11.42l0.02-0.02c5.48-4.59,10.36-9.72,14.34-15.67l0.02-0.02c3.94-3.67,7.45-7.76,11.42-11.4h0.02
		v-0.02l0.02-0.02c1.85-0.85,3.13-2.35,4.19-4.05h0.02v-0.02c1.33-0.85,2.66-1.72,2.99-3.47c-0.06-0.56,0.19-0.83,0.75-0.79
		c5.38-4.75,10.57-9.69,14.72-15.59H464c0.02,0,0.04,0,0.06-0.02c2.08-0.54,2.41-2.72,3.78-3.96
		c13.45-12.37,24.85-26.55,35.98-40.94c4.9-6.31,10.36-12.29,14.16-19.41H518v-0.02c0.02,0,0.02-0.02,0.04-0.02
		c1.43-1.06,2.37-2.51,3.09-4.09c0.02-0.02,0.02-0.04,0.02-0.06h0.02v-0.02c1.79-2.01,4.07-3.59,5.11-6.23c0-0.02,0-0.02,0.02-0.04
		v-0.02c1.35-1.1,2.43-2.41,3.18-4.03v-0.02h0.02v-0.02c6.06-5.83,10.48-13,15.69-19.53c1.22-0.89,2.51-1.7,2.97-3.26v-0.02h0.02
		v-0.02c3.82-2.97,6.21-7.04,8.43-11.23c9.74-10.61,16.84-23.19,25.89-34.29h0.02v-0.02c3.32-2.99,6.06-6.46,8.08-10.46l0.02-0.02
		h0.02v-0.02c3.94-3.43,5.54-9.05,10.75-11.67c1.22,2.03,0.81,3.94,0.89,5.77c0.08,1.85,0.06,3.67,0,5.5
		c-0.29,6.95-1.47,13.85-1.52,20.82c-0.06,19.68-0.44,39.38,1.06,59.04c0.39,5.07,0.23,10.34,2.64,15.07v0.02h0.02v0.02
		c1.16,5.19,0.85,10.63,2.97,15.63c0.04,0.54,0.08,1.08,0.15,1.62c1.35,4.92,1.52,10.19,4.23,14.74v0.02c0.02,0,0.02,0.02,0.02,0.02
		c0,0.02,0,0.02,0,0.04c1.95,5.94,4.55,11.5,8.26,16.55v0.02h0.02v0.04c0.6,1.68,1.2,3.38,2.93,4.3c3.97,6.44,9.05,11.71,15.76,15.3
		c0,0,0.02,0,0.02,0.02c0,0,0,0.02,0.02,0.02c0.02,0.02,0.02,0.02,0.04,0.02c4.94,3.92,10.48,6.52,16.77,7.37l0.29-0.19l0.29,0.23
		c4.48,1.76,8.99,1.7,13.49,0.02c0.5,0,1.02-0.02,1.52-0.04c7.2-1.1,14.18-2.62,20.07-7.27c0,0,0.02,0,0.02-0.02h0.02l0.02-0.02
		c0.02,0,0.02,0,0.04,0l0.02-0.02c2.1-1.33,4.77-1.76,6.12-4.13c0.02-0.02,0.02-0.04,0.04-0.04c13.6-9.22,24.37-21.24,34.13-34.25
		c0.02,0,0.02-0.02,0.04-0.04h0.02c1.58-1.08,2.55-2.57,3.18-4.34c-0.08-0.54,0.15-0.81,0.69-0.79c3.55-3.57,7.72-6.5,10.73-10.63
		c-0.04-0.56,0.23-0.81,0.79-0.77c4.73-3.76,8.16-8.64,11.56-13.54h0.02v-0.02c1.72-1.04,2.99-2.53,4.26-4.07l0.02-0.02v-0.02
		c-0.02,0-0.04,0.02-0.06,0.02c7-6.98,13.43-14.41,18.77-22.73h0.02v-0.04c0.02-0.02,0.04-0.02,0.04-0.04
		c6.98-6.71,12.25-14.7,17.4-22.79c0-0.02,0.02-0.02,0.02-0.04h0.02v-0.02c0.02-0.02,0.02-0.04,0.06-0.06
		c1.22-1.12,2.24-2.39,3.2-3.76c2.1-2.97,3.28-2.78,4.73,0.58c0.93,2.14,0.83,4.67,2.66,6.41c0,0.02,0,0.02,0,0.04h0.02
		c1.29,3.82,2.08,7.83,4.23,11.31l0.02,0.02v0.02l0.02,0.02c2.8,7.81,4.9,15.84,7.16,23.83v0.02c0.02,0.02,0.02,0.02,0.02,0.04v0.02
		c1.16,3.88,1.76,7.97,4.07,11.44c1.64,3.67,2.01,7.85,4.19,11.33c0,0.02,0,0.02,0,0.04h0.02v0.02c2.14,6.06,1.1,12.62,2.99,18.72
		c0.06,1.18,0.1,2.37,0.15,3.55c1.14,5.15,2.22,10.26,2.62,15.55c0.19,2.62-0.31,5.5,1.45,7.89l0.27,0.35l-0.19,0.39
		c1.95,6.89,2.06,14.18,4.15,21.07c0,0.5-0.02,1-0.02,1.49c0.71,3.49,0.73,6.98,0,10.46c0.52,0.85,0.5,1.7,0.02,2.55
		c-1.12,1.02-2.22,2.01-3.09,3.26H838v0.02c-14.41,4.69-26.32,13.43-37.49,23.31c-2.24,1.99-4.88,3.78-5.92,6.87v0.02
		c-0.02,0-0.02,0-0.02,0.02c-1.89,1.14-2.28,3.24-3.28,4.96l-0.02,0.02c-2.64,3.13-3.3,7.02-4.13,10.86
		c-0.02,0.5-0.06,1.02-0.08,1.52c-1.43,2.76-1.58,5.5,0.06,8.24h0.02v0.02c1.45,5.52,6.12,6.31,10.73,7.2
		c1.39-0.44,2.8-0.42,4.19,0.02c4.82-0.79,9.78-1.04,13.91-4.15h0.04l0.02-0.02c8.99-2.64,15.3-9.4,22.63-14.66
		c0.02,0,0.02,0,0.04-0.02c0.02,0,0.02,0,0.04-0.02l0.02-0.02c6.79-3.49,11.71-8.86,15.69-15.28v-0.02c0,0,0.02,0,0.02-0.02
		c4.38-4.77,8.49-9.72,11.42-15.55c1.74-0.89,2.26-2.64,2.97-4.26v-0.02l0.02-0.02c1.16-0.75,1.85-1.87,2.59-3.05
		c4.19-6.77,7.7-14.03,15.65-17.73c2.99-1.39,6.12-2.8,8.66-5.15l0.02-0.02h0.02c0.02,0,0.04,0,0.06-0.02
		c5.15-2.18,10.07-4.73,14.43-8.28l0.02-0.02h0.04c1.68-0.62,3.38-1.2,4.3-2.97c-0.02,0-0.02,0-0.04,0c2.2-1.18,4.57-2.16,6.1-4.28
		h0.02c0-0.02,0.02-0.02,0.02-0.04c1.97-1.08,4.09-1.93,5.25-4.03h0.02v-0.02c1.56-0.83,3.2-1.56,4.09-3.2
		c5.29-3.22,10.03-7.08,14.53-11.27l0.02-0.02h0.02c0.02,0,0.02-0.02,0.02-0.02c1.85-1.25,3.84-2.28,5.07-4.26l0.02-0.02h0.02v-0.02
		c4.73-3.49,9.36-7.1,13.47-11.33l0.02-0.02c0.02,0,0.02-0.02,0.02-0.02c5.07-2.76,8.18-7.66,12.33-11.42h0.02v-0.02h0.02
		c2.08-0.64,3.22-2.24,4.28-4.01v-0.02h0.02v-0.02c4.3-2.57,7.47-6.5,11.15-9.78c6.73-6.02,13.6-12.08,17.77-20.39v-0.02
		c0-0.02,0-0.02,0.02-0.04c2.22-2.01,3.78-4.53,5.19-7.16v-0.02c0.02,0,0.02-0.02,0.04-0.02c1.89-1.31,3.05-3.18,4.13-5.13
		l0.02-0.02h0.02v-0.02c3.2-2.89,5.21-6.6,7.31-10.28c1.83-0.81,2.33-2.57,2.99-4.21c0-0.02,0-0.02,0.02-0.04l0.02-0.02
		c2.95-2.76,4.36-6.66,7-9.49c0.79,7.6,3.09,14.7,7.78,20.95v0.02h0.02v0.02l0.02,0.02c0.91,1.79,1.54,3.74,3.13,5.13h0.02
		l0.02,0.02c1.18,2.18,2.08,4.57,4.11,6.19h0.02v0.02h0.02c3.99,7.27,10.01,12.23,17.52,15.55c0.02,0.02,0.02,0.02,0.04,0.02
		c0.02,0,0.02,0,0.04,0h0.02c0.02,0,0.04,0.02,0.06,0.02c5.58,2.53,11.48,3.94,17.5,4.65c5.13,0.6,10.52,1.72,15.34-1.54h0.02
		c0.02,0,0.02,0,0.04,0c6.85-0.52,12.27-3.55,16.84-8.7c4.67-5.29,8.95-10.9,13.45-16.32c3.55-4.28,6.37-9.15,10.48-13.2
		c0.1,2.99,0.75,5.85-0.1,8.7c-0.04,0.87-0.08,1.76-0.12,2.64c-1.6,7.62-2.7,15.24,0.04,22.84l0.27,0.33l-0.19,0.37
		c1.62,6.23,1.54,12.83,4.17,18.85v0.08c0.02,0,0.02,0.02,0.02,0.02h0.02c1.2,3.84,1.33,8.1,4.09,11.4c0,0.02,0.02,0.02,0.02,0.02
		c1.1,3.47,1,7.27,3.03,10.44l0.23,0.23l-0.12,0.27c1.06,3.07,1.49,6.41,3.94,8.84l0.29,0.19l-0.08,0.33
		c2.59,5.98,7.2,10.19,12.41,13.83l0.02,0.02v0.02l0.02,0.02c1.68,1.97,3.92,3.03,6.23,3.99l0.02,0.02v0.02
		c0,0.02,0.02,0.02,0.02,0.02h0.02c2.33,2.2,5.21,3.26,8.2,4.17h0.02l0.04,0.02l0.02,0.02c2.14,1.62,4.71,2.22,7.18,3.05
		c0.02,0.02,0.06,0.02,0.08,0.04c0.02,0,0.02,0,0.04,0c5.04,2.3,10.11,2.18,15.15,0.04c0.02,0,0.02,0,0.04,0
		c0.02,0,0.04-0.02,0.06-0.04c2.22-0.66,4.61-1.04,6.23-3.01h0.02v-0.02c2.43-0.85,4.55-2.16,6.6-3.72
		c10.42-7.81,19.76-16.9,29.6-25.37c4.59-3.94,9.05-8.32,12.39-13.54c0.02,0,0.02-0.02,0.04-0.02c0-0.02,0-0.02,0-0.04l0.02-0.02
		c4.98-6.37,10.92-11.9,15.55-18.56v-0.02h0.02c0-0.02,0-0.02,0.04-0.04c1.91-1.27,2.99-3.22,4.19-5.07c0-0.02,0.02-0.02,0.02-0.02
		h0.02v-0.02c2.97-3.03,6-6.06,7.33-10.28c2.78-3.4,6.91-5.94,7.2-10.98C1295.45,909.28,1295.45,908.38,1295.47,907.47z
		 M690.26,665.77c6.02-11.52,9.65-23.94,13.97-36.08c2.99-8.43,8.12-15.17,14.28-21.22c3.92-3.82,7.99-2.84,10.44,2.12
		c3.59,7.29,2.12,14.61-0.98,21.51c-6.37,14.16-14.32,27.55-23.6,39.96c-8.03,10.71-16.77,20.95-25.12,31.7
		C682.76,691.01,684.07,677.62,690.26,665.77z M350.89,679.08c-3.11-0.58-4.61-2.89-5.44-5.63c-1.95-6.48-0.71-12.68,2.51-18.37
		c2.18-3.88,5.07-7.35,7.62-11.02c2.66-3.78,4.03-2.1,5.38,1.1c2.49,5.9,1.27,12.29,2.74,17.94c-0.44,4.94-1.72,9.18-4.59,12.81
		C357.03,678.54,354.25,679.7,350.89,679.08z M838.46,1040.33c-5.44,17.77-14.93,32.05-33.71,38.05c-7.51-0.25-7.51-0.25-7.47-6.41
		c6.79-18.45,21.96-27.67,38.8-34.92C840.03,1035.35,838.98,1038.63,838.46,1040.33z M836.19,830.56
		c-4.73-8.97-5.85-18.14-4.59-27.15c0.89-6.25,6.04-10.65,11.92-13.35c2.51-1.16,4.86-0.04,6.27,2.2c3.53,5.56,4.96,11.6,1.81,17.65
		C847.67,817.38,842.53,824.06,836.19,830.56z M1917.48,906.89c-2.57-1.95-5.56-2.55-8.14,0.33l-0.04,0.04
		c0,0.02-0.02,0.02-0.02,0.02c-1.56,0.93-2.43,2.43-3.11,4.05c0,0.02,0,0.02,0,0.04c0,0.02-0.02,0.02-0.02,0.02
		c-4.86,6.46-10.94,11.73-15.47,18.43c-0.02,0.04-0.06,0.08-0.1,0.12c-0.02,0.02-0.04,0.02-0.06,0.04v0.02
		c-4.05,4.26-8.2,8.43-11.29,13.49c0,0,0,0.02-0.02,0.02c-3.03,2.59-5.36,5.77-7.41,9.18h-0.02v0.02c-2.1,1.12-2.78,3.45-4.13,5.17
		h-0.02v0.02h-0.02c-1.31,0.77-2.53,1.64-2.99,3.2v0.02h-0.02c-1.58,0.44-2.39,1.74-3.26,2.93c-0.37,0.35-0.75,0.71-1.12,1.06
		c-4.21,3.32-8.41,6.75-12.12,10.65l-0.02,0.02c0,0.02-0.02,0.02-0.02,0.02c-5.29,3.57-10.09,7.72-14.53,12.31h-0.02
		c-0.02,0-0.02,0.02-0.02,0.02c-1.6,0.44-2.45,1.66-3.22,2.97h-0.02v0.02c-3.13,1.99-5.92,4.36-8.1,7.39h-0.02v0.02
		c-0.02,0.02-0.02,0.02-0.04,0.02c-5.19,3.36-10.3,6.81-14.64,11.27c0,0.02-0.02,0.02-0.02,0.02l0.06-0.02
		c-2.03,1.43-4.44,2.37-6.17,4.28c-0.02,0.02-0.02,0.02-0.04,0.04c-11.75,5.27-22.67,12.5-35.44,15.42l-0.25,0.17l-0.21-0.19
		c-4.55,0.81-9.45,0.89-13.45,3.16c-10.52,5.94-34.46,1.31-42.45-9.26c-1.31-1.74-2.1-3.92-3.99-5.25
		c-0.02-0.02-0.02-0.02-0.04-0.02c-1.1-2.57-2.2-5.13-4.07-7.27h-0.02v-0.04h-0.02c-1.02-2.43-1.47-5.09-3.03-7.29h-0.02v-0.02
		c-1.33-4.15-1.87-8.55-4.17-12.35v-0.02c-0.02,0,0-0.02,0-0.02v-0.02c-0.02,0-0.02-0.02-0.02-0.02c-1.7-4.71-1.95-9.86-4.19-14.39
		c0-0.02,0-0.02,0-0.04v-0.02l-0.02-0.02c-1.18-4.15-1.08-8.59-2.93-12.58c-0.04-0.5-0.06-1-0.1-1.47c-1.16-3.47-0.5-5.61,3.67-5.87
		c1.99-0.15,4.03-0.71,5.65-2.12c0.02,0,0.02-0.02,0.04-0.02l0.02-0.02c2.45-0.81,5.07-1.29,7.04-3.2c0.02,0,0.02,0,0.04-0.02h0.02
		l0.02-0.02h0.02l0.02-0.02c7.04-2.93,13.66-6.73,20.51-10.07c2.99-1.45,6.48-2.3,8.72-5.13l0.17-0.29l0.33,0.06
		c2.37-1.1,4.8-2.14,6.58-4.17c0.02-0.02,0.02-0.02,0.04-0.04c7.62-4.36,14.18-10.11,20.41-16.25c7.76-7.66,15.84-15.03,21.98-24.18
		c0-0.02,0.02-0.02,0.02-0.04h0.02c2.93-2.7,5.54-5.63,7.39-9.2h0.02c0.02,0,0-0.02,0-0.02h0.02c1.79-1.43,3.09-3.18,4.05-5.21
		l0.02-0.02l0.04-0.04c2.86-2.74,5.31-5.81,7.18-9.32c0-0.02,0.02-0.04,0.02-0.06h0.02v-0.02c5-5.83,7.54-13.14,11.36-19.64
		c0.02,0,0.02-0.02,0.04-0.02v-0.02c0.02,0,0.02-0.02,0.02-0.02v-0.02c2.39-2.2,2.95-5.34,4.23-8.12v-0.02l0.02-0.02
		c2.12-1.97,2.12-4.8,2.99-7.27c0.02-0.02,0.02-0.02,0.04-0.04v-0.02c2.49-3.74,2.86-8.2,4.21-12.35c0,0,0-0.02,0.02-0.02v-0.04
		c2.26-4.3,2.74-9.18,4.11-13.76l-0.17-0.35l0.27-0.29c2.1-3.53,2.16-7.62,2.99-11.48c0-0.02,0-0.02,0-0.04l0.02-0.02
		c1.6-1.99,1.35-4.48,1.62-6.77c1.04-9.28,1.39-18.62,2.57-27.88l-0.21-0.25l0.19-0.27c2.35-5.69,1.81-11.81,1.91-17.67
		c0.15-6.89,0.52-13.99-1.89-20.74l-0.23-0.29l0.21-0.29c-0.25-0.56-0.33-1.14-0.35-1.74c-0.04-0.58-0.04-1.2-0.1-1.79
		c-1.08-9.96-2.64-19.83-6.73-29.1l-0.27-0.23l0.17-0.33c-1.76-7.1-5.98-13.16-8.35-19.99c0-0.02,0-0.02,0-0.04
		c-0.02-0.02-0.02-0.04-0.04-0.06c-0.75-2.24-0.81-4.86-3.3-6.19c-0.58,0-0.85-0.29-0.75-0.87c-0.73-1.7-1.76-3.16-3.16-4.38
		c0,0.02,0.02,0.06,0.02,0.08c-1.99-3.78-3.76-7.7-7.16-10.5c-0.02,0-0.02-0.02-0.02-0.02c-3.2-4.69-6.73-9.09-11.46-12.35
		l-0.04-0.04c-1.97-3.3-4.73-5.71-8.2-7.33c-2.18-2.59-5.58-2.68-8.37-4.03c0,0-0.02,0-0.02-0.02l-0.02-0.02
		c-1.62-1.89-4.11-2.1-6.17-3.18c0.02,0,0.02,0,0.04,0c-2.24-2.01-4.98-3.07-7.76-4.05l-0.31,0.12l-0.21-0.25
		c-1.7-1.7-4.07-1.72-6.19-2.3c-4.94-1.39-10.11-1.14-15.17-1.81c-2.93,0.79-5.83,0.21-8.74-0.12c-6.21-2.06-12.62-0.66-18.91-0.89
		c-3.38-0.12-6.91-0.85-10.15,0.95c-0.52,0.04-1.04,0.08-1.56,0.1c-5.36,1.22-11,1.49-15.98,4.15h-0.02l-0.02,0.02
		c-6.89,1.47-13.27,4.26-19.58,7.29h-0.02c-0.02,0.02-0.06,0.04-0.08,0.04c-2.95,1-5.94,1.95-8.28,4.11l-0.02,0.02
		c-2.37,0.95-4.46,2.3-6.6,3.72c-8.88,5.9-14.91,14.28-20.43,23.15l-0.02,0.02c-2.01,1.22-2.93,3.3-3.99,5.27
		c-0.02,0-0.02,0.02-0.04,0.02c-2.2,1.41-2.22,4.01-3.22,6.1v0.02h-0.02v0.02c-1.29,1.52-1.49,3.36-1.58,5.23
		c-0.15,3.49,0.87,5.92,4.82,6.62c5.31,0.95,5.34-2.84,5.96-6.21l-0.17-0.29l0.23-0.25c2.03-2.47,2.53-5.71,4.03-8.41l0.02-0.02
		v-0.02h0.02v-0.02c3.72-4.9,7.66-9.57,11.33-14.49h0.02c0,0,0-0.02,0.02-0.02c1.25-0.83,2.3-1.87,3.07-3.11c0,0,0.02,0,0.02-0.02
		c0,0,0-0.02,0.02-0.02h0.02v-0.02c3.61-2.99,7.64-5.42,11.4-8.22h0.02l0.02-0.02c0.02,0,0.02-0.02,0.02-0.02
		c4.77-2.49,9.84-4.46,14.49-7.2h0.04l0.02-0.02c7.93-2.57,15.88-5.02,23.96-7.1c0.58-0.04,1.16-0.08,1.74-0.15
		c14.43-2.39,28.86-1.58,43.28,0c0.56,0.04,1.12,0.08,1.68,0.12c6.23,1.56,12.33,3.47,17.77,7.04l0.02,0.02
		c0.02,0.02,0.02,0.02,0.04,0.04c2.55,2.76,6.06,3.36,9.47,4.19l0.42-0.15l0.31,0.29c7.2,4.8,12.83,11.29,18.85,17.38v0.02
		l0.02,0.02c0,0.02,0.02,0.02,0.02,0.04c0.93,2.06,2.26,3.8,4.05,5.17v0.02h0.02c0,0.02,0,0.02,0,0.04h0.02
		c1.27,2.14,2.03,4.61,4.19,6.14c0,0.02,0.02,0.02,0.02,0.02v0.02h0.02c0.54,1.72,1.58,3.11,2.93,4.3c0.33,0.37,0.66,0.73,1,1.1
		c1.43,1.85,1.76,4.26,3.18,6.1h0.02v0.02c0.83,3.34,1.72,6.62,4.19,9.22v0.02c0,0.02,0,0.02,0,0.04h0.02v0.02
		c0.91,2.47,1.08,5.23,2.91,7.35c0.87,1.29-0.17,3.05,1.1,4.23c1.29,3.36,1.37,7.1,3.18,10.3l0.23,0.25l-0.17,0.27
		c1.64,7.14,2.53,14.39,3.01,21.69c0.23,3.32-0.93,6.83,1.12,9.96c-0.02,0.5-0.02,1-0.04,1.49c0.77,4.73,0.73,9.4,0.08,14.14v0.02
		h-0.02c0,0.02,0,0.02,0,0.04c-1.66,2.55-1.41,5.48-1.68,8.32c-0.85,9.84-0.44,19.78-2.49,29.52l0.17,0.21l-0.17,0.21
		c-2.47,5.42-2.37,11.46-4.13,17.04l0.17,0.35l-0.27,0.27c-1.81,2.86-1.79,6.33-2.95,9.42l-0.02,0.02v0.02h-0.02v0.02
		c-2.37,3.4-2.49,7.66-4.17,11.33c0,0.02,0,0.02,0,0.04h-0.02c-2.03,2.84-2.43,6.37-3.96,9.4h-0.02c0,0.02,0,0.02,0,0.04
		c0,0.02-0.02,0.02-0.04,0.04v0.02c-1.68,1.7-2.08,4.07-3.22,6.02v0.02l-0.04,0.02v0.02c-2.16,1.95-2.76,4.8-4.13,7.2l-0.02,0.02
		c-1.99,2.08-3.11,4.65-4.17,7.27c0-0.02,0-0.02,0-0.04c-3.43,5.46-8.05,10.07-11.23,15.69h-0.02v0.02l-0.02,0.02
		c-1.52,1-2.37,2.51-3.16,4.09v0.04h-0.02v0.02c-4.34,4.38-7.58,9.63-11.4,14.43h-0.02v0.02c-0.02,0.02-0.02,0.02-0.04,0.04
		c-6.48,6.62-12.95,13.29-19.72,19.62c-0.02,0.02-0.02,0.02-0.04,0.02v0.02c-0.02,0-0.04,0.02-0.06,0.04
		c-11.71,7.58-21.49,18.1-35.06,22.88h-0.02c-0.04,0.02-0.06,0.04-0.1,0.04c-5.02,1.37-9.3,4.07-13.37,7.2h-0.02
		c-0.02,0-0.02,0.02-0.02,0.02h-0.02c-2.82,1.16-5.4,2.76-8.16,4.01c-6.31,2.82-7.45,2.22-9.13-4.38c-0.08-0.31-0.33-0.58-0.54-0.85
		c-0.42-1.18-0.6-2.37-0.06-3.55c-3.94-34.65-3.99-69.29,0-103.94c0.02-0.5,0.02-1,0.02-1.49c0.91-1.12,0.98-2.49,1.12-3.86
		c0.85-8.12,2.24-16.15,2.97-24.27v-0.02c0-0.02,0-0.02,0-0.04c1.02-4.09,1.68-8.26,1.66-12.52c0-3.65,1.16-6.37,4.38-8.72
		c5.69-4.09,10.86-8.95,15.76-13.99l0.02-0.02h0.02v-0.02c3.28-1.79,6.27-3.92,8.41-7.08l0.04-0.02
		c7.72-6.39,13.78-14.12,18.43-22.96c0,0,0.02,0,0.02-0.02c0.02,0,0.02,0,0.04-0.02c0-0.02,0-0.02,0-0.04
		c3.38-3.72,6.1-7.89,8.41-12.29c3.47-4.11,5.71-8.86,7.16-14.03l-0.15-0.27l0.23-0.21c3.74-9.57,4.15-19.14,0.02-28.69
		c-0.02-0.08-0.04-0.15-0.08-0.23v-0.02c0-0.02-0.02-0.04-0.02-0.06c-1.16-3.67-3.55-6.08-7.22-7.18c0,0-0.02,0-0.02-0.02
		l-0.02-0.02h-0.02c-5.44-2.97-10.88-1.66-16.32,0c-0.02,0-0.02,0-0.04,0c-0.02,0-0.02,0-0.02,0v0.02h-0.02
		c-6.79,0.93-13.27,2.62-18.58,7.31l-0.02,0.02c0,0.02-0.02,0.02-0.02,0.02c-4.3,2.37-8.97,4.3-12.14,8.18
		c-7.12,8.7-13.54,17.92-20.05,27.07c0.02-0.02,0.02-0.06,0.04-0.08c-0.02,0-0.02,0.02-0.04,0.04c-2.97,3.45-5.44,7.2-7.14,11.44
		l-0.02,0.02v0.02h-0.02c-2.22,2.33-2.95,5.4-4.15,8.22l-0.02,0.02v0.02l-0.02,0.02v0.02c-3.32,5.48-5.44,11.44-7.2,17.56
		l-0.02,0.02v0.02l-0.02,0.02c-2.51,3.72-2.82,8.22-4.23,12.33l-0.02,0.02v0.04c-1.41,1.43-1.68,3.36-2.06,5.11
		c-2.12,10.23-5.77,18.97-17.94,20.26c-0.29,0.04-0.5,0.37-0.71,0.66c0,0,0,0.02-0.02,0.02c-8.53,1.64-17.19,2.82-24.91,7.2
		c0,0.02-0.02,0.02-0.02,0.02c0.04,0,0.06-0.02,0.1-0.02c-3.11,1.39-6.71,1.76-9.38,4.19c-0.02,0.02-0.02,0.02-0.04,0.04
		c-2.16,1.18-4.75,1.76-6.17,4.07l-0.02,0.02l-0.02,0.02c-1.66,0.64-3.18,1.49-4.11,3.09c0,0.02-0.02,0.02-0.02,0.02v0.02
		c-2.39,1.43-5.31,1.91-7.37,3.97h-0.02v0.02h-0.02c-4.15,1.43-7.22,4.36-10.38,7.22c-0.37,0.35-0.73,0.68-1.1,1.04
		c-2.64,2.16-5.31,4.34-7.06,7.35l-0.02,0.02c-0.02,0-0.02,0.02-0.02,0.02c-2.2,0.91-3.22,3.11-4.42,4.82
		c-4.48,6.25-11.6,10.38-14.26,18.02v0.02c-4.59,5.29-8.16,11.33-12.31,16.94c-4.98,6.73-10.17,13.29-14.74,20.3h-0.02v0.02
		c-2.03,1.22-3.01,3.26-4.11,5.25c-5.4,6.37-11.67,12.08-15.4,19.76c-3.74,3.57-1.25,5.92,1.64,8.16c1.04,1.29,1.97,0.33,2.93-0.12
		c4.05-1.41,5.83-5.11,8.2-8.2c4.82-6.37,10.65-11.92,14.8-18.79v-0.02c0.02-0.02,0.02-0.02,0.04-0.04
		c2.39-1.93,3.84-4.53,5.29-7.12h0.02c1.87-0.81,2.22-2.66,3.05-4.19c0,0,0.02,0,0.02-0.02h0.02v-0.02
		c3.38-3.34,5.81-7.37,8.12-11.48h0.02v-0.02h0.02c1.52-0.58,1.76-2.97,3.84-2.78c0.73,1.22,0.27,2.39,0.17,3.57
		c-0.71,7.83-1.43,15.67,1.45,23.29l0.25,0.31l-0.19,0.35c0.21,0.48,0.33,0.98,0.42,1.47c1.45,9.84,4.3,19.1,11,26.78
		c0.02,0.02,0.02,0.02,0.02,0.04v0.02h0.02c2.74,5.07,7.64,8.18,11.36,12.39l0.02,0.02v0.02c0.02,0,0.02,0.02,0.02,0.02
		c1.99,3.28,4.67,5.77,8.22,7.27v0.02c0.02,0,0.02,0,0.04,0c1.83,1.81,3.97,3.05,6.35,3.94h0.04c0,0.02,0.02,0.02,0.02,0.02h0.02
		c4.34,3.32,9.15,5.69,14.37,7.27c0.02,0.02,0.02,0.04,0.04,0.04c1.08,1.29,2.7,1.29,4.11,1.79c11.77,4.05,23.98,4.46,36.2,3.96
		c3.61-0.15,4.53,1.2,5.79,4.05c2.62,5.9,4.8,12.06,8.89,17.21v0.02c0.04,0.02,0.04,0.02,0.04,0.04v0.02
		c1.2,2.49,1.89,5.29,4.09,7.22v0.02h0.02l0.02,0.02c4.84,9.34,11.29,17.54,18.27,25.33c4.84,5.36,9.99,10.36,16.9,13.06h0.02
		l0.02,0.02h0.02c0,0.02,0.02,0.02,0.02,0.02c7.83,4.36,14.7,10.67,24.23,11.38c0.52,0.06,1.06,0.1,1.6,0.15
		c1.43,1.12,3.18,1.1,4.84,1.39c0.15,0.02,0.29,0.04,0.42,0.08c0.19,0.02,0.37,0.08,0.56,0.12c7.89,1.97,15.78,0.6,23.69-0.08
		c0.19,0.02,0.35,0.02,0.54,0.02h0.39c1.68,0.02,3.3-0.17,4.84-1c0.12-0.08,0.25-0.19,0.39-0.25c0.06-0.06,0.15-0.1,0.23-0.15
		c2.33-1.41,4.92-1.76,7.56-2.08c0.33-0.04,0.69-0.08,1.04-0.12c0.52-0.06,1.04-0.12,1.56-0.21c2.51-1.04,5.52-0.19,7.83-2.06
		l0.02-0.02c6.23-0.77,11.31-4.09,16.44-7.31c0.02,0,0.02,0,0.04,0c0.02-0.02,0.02-0.02,0.04-0.04l0.02-0.02
		c2.8-1.27,5.92-1.91,8.3-4.05l0.02-0.02c0.02,0,0.02-0.02,0.02-0.02c4.86-1.68,9.47-3.82,13.43-7.18h0.02v-0.02h0.02
		c0.02,0,0.02-0.02,0.02-0.02c1.85-1.18,4.07-1.95,5.17-4.07h0.02v-0.02h0.02c3.49-1.93,6.56-4.36,9.18-7.39v-0.02l0.02-0.02
		c0.02,0,0.02-0.02,0.02-0.02c4.01-1.68,6.25-5.38,9.3-8.2h0.02v-0.02c0.02,0,0.02,0,0.04,0c1.66-0.52,2.74-1.6,3.16-3.32
		c-0.04-0.58,0.23-0.83,0.79-0.77c7.89-5.96,15.84-11.89,23.13-18.6c0.02,0,0.02-0.02,0.04-0.02v-0.02c4.3-3.3,8.16-7.04,11.36-11.4
		h0.02v-0.02h0.02v-0.02c2.41-2.14,4.55-4.48,6.12-7.31c-0.02,0-0.02,0.02-0.04,0.02c0-0.02,0.02-0.04,0.02-0.04
		c0.02,0,0.02,0,0.04-0.02c4.61-4.77,8.43-10.21,12.46-15.47c0,0,0.02,0,0.02-0.02l0.02-0.02v-0.02l0.02-0.02
		c3.84-4.13,8.32-7.58,11.36-12.43l0.02-0.02v-0.02c3.24-3.03,6.17-6.31,8.32-10.23l0.02-0.02h0.02c2.37-1.52,3.74-3.94,5.15-6.19
		C1918.66,911.95,1920.41,909.13,1917.48,906.89z M1731.6,684.41c6.87-3.38,11.21-0.6,12.21,6.89c1.41,10.75-1.95,20.03-7.51,28.86
		c-11.19,17.75-24.12,34-41.08,46.69c-1.06,0.81-1.87,2.1-3.65,1.83c2.91-17.77,9.42-34.46,14.59-51.55
		c3.7-12.19,11.31-21.09,20.32-29.29C1727.97,686.47,1729.77,685.31,1731.6,684.41z M1579.71,918.64c-0.6-4.09-1.56-8.14-2.12-12.25
		c-4.8-34.58,4.36-64.77,29.96-88.81c9.8-9.22,23.37-12.62,36.47-18.45c-2.28,7.6-4.84,14.01-6.08,20.68
		c-8.22,43.7-11,87.38,1.31,130.87c0.81,2.8,0.29,4.84-2.8,4.4c-11.73-1.62-23.94-0.52-34.94-6.23
		C1589.13,942.45,1581.8,932.46,1579.71,918.64z M2764.19,703.35c-6.12-0.83-13.37-3.39-19.98-7.57c-2.75-1.74-4.04-4.48-4.32-7.83
		c-1.22-14.86,12.19-25.59,26.38-21.01c0.98,0.32,1.96,0.67,2.92,1.03c8.17,3.04,8.34,3.95,4.98,11.74
		c-2.03,4.7-3.12,9.81-4.61,14.74c-0.36,1.19-0.17,2.34,0.24,3.53C2771.64,703.29,2771.51,703.43,2764.19,703.35z"
      />
    </g>
  </svg>
)
